@font-face {
	font-family: 'adjust';
	src:url('../fonts/adjust-font.eot');
	src:url('../fonts/adjust-font.eot?#iefix') format('embedded-opentype'),
		url('../fonts/adjust-font.woff') format('woff'),
		url('../fonts/adjust-font.ttf') format('truetype'),
		url('../fonts/adjust-font.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Raleway:200,300i,300,500,600,600i,800i,900,900i');