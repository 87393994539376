a.button-close-news {
    position: absolute;
    right: 720px;
    top: 20px;
    fill:white;
    height: 20px;
    width: 20px;

    @media screen and (max-width: 1400px) {
        right: calc(50% + 20px);
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 510px) {

    }
}

div.section-background {
    
}

div.section-content {

    a.button-close-news {
        position: relative;
        margin-bottom: 10px;
        right: unset;
        top: unset;
        display: none;
        fill: #fff;
        height: 20px;
        width: 20px;

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    h2.news {

        @media screen and (max-width: 1400px) {
        }
    
        @media screen and (max-width: 768px) {
        }
    
        @media screen and (max-width: 510px) {
            font-size: 40px !important;
            padding-right: 20px;
            width: fit-content;
        }
    }
}

div.section-inner#news-section-inner {
    
    @media screen and (max-width: 1400px) {
        width: 50%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 510px) {
        width: 100%;
    }

    div.section-inner-block {
        min-height: 330px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 70px;

        @media screen and (max-width: 1400px) {
        }
    
        @media screen and (max-width: 768px) {
        }
    
        @media screen and (max-width: 510px) {
            height: fit-content;
            padding-bottom: 20px;
        }

        h4 {
            top: 0;
            height: fit-content;
            max-width: 80%;
            transform: none;
            margin-bottom: 0;
            flex-shrink: unset;

            @media screen and (max-width: 1400px) {
            }
        
            @media screen and (max-width: 768px) {
            }
        
            @media screen and (max-width: 510px) {
            }
        }

        p {
            color: white;
            font-weight: 400;
            position: relative;
            height: inherit;

            @media screen and (max-width: 1400px) {
            }
        
            @media screen and (max-width: 768px) {
            }
        
            @media screen and (max-width: 510px) {
            }
        }

        span.date {
            color: white;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 20px;
        }

        a {
            width: fit-content;
            height: fit-content;
            position: absolute;
            bottom: 20px;
            min-width: 100px;

            @media screen and (max-width: 1400px) {
            }
        
            @media screen and (max-width: 768px) {
            }
        
            @media screen and (max-width: 510px) {
                // width: calc(100% - 40px);
                width: auto;
                position: relative;
                bottom: 0;
            }

            div.cta.cta-inner {
                top: 0;
                transform: none;
                position: relative;
                width: auto;
                right: inherit;

                @media screen and (max-width: 1400px) {
                    
                }
            
                @media screen and (max-width: 768px) {
                }
            
                @media screen and (max-width: 510px) {
                    left: unset;
                }
            }
        }
    }
}

div.section.section-news {
    

    @media screen and (max-width: 1400px) {}
    
    @media screen and (max-width: 768px) {
        display: inline-table;
        height: 100vh;
    }

    @media screen and (max-width: 510px) {}

    & > div,
    .fp-tableCell {
        

        @media screen and (max-width: 1400px) {}
        
        @media screen and (max-width: 768px) {
            display: block;
            height: inherit;
        }
    
        @media screen and (max-width: 510px) {
            
        }

        div.section-content {

            @media screen and (max-width: 1400px) {}
            
            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
        
            @media screen and (max-width: 510px) {
            }
        }
    }
}