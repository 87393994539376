.section-inner form {
    overflow: hidden;
    margin: 35px 0px;

    // input[type='checkbox'] {
    //     float: left;
    //     clear: both;
    //     padding: 10px;
    //     width: 20px;
    // }

    input {
        width: calc(50% - 15px);
        padding: 15px;
        margin-bottom: 15px;
        margin-right: 15px;
        border: none;
        border-bottom: 1px solid black;
        float: left;
        font-family: Raleway,sans-serif;

        &[type='checkbox'] {
            float: left;
            clear: both;
            padding: 10px;
            width: 20px;        
            margin-top: 20px;
        }

        &.wide {
            width: calc(100% - 15px);
        }

        &.text {
            //min-height: 150px;
        }
    }

    label.info_gdpr{
        display: block;
        float: left;
        width: calc(100% - 50px);
        font-size: 12px;        
        margin-top: 20px;
    }

    button.btn_contact {
        border: 4px solid black;
        padding: 15px 30px;
        display: inline-block;
        margin-top: 35px;
        text-decoration: none;
        color: inherit; 
        font-weight: 800;
        text-transform: uppercase;
        background: white;
        position: relative;
        overflow: hidden;
        float: left;

        span {
            position: relative;
            color: #000;
            font-family: Raleway,sans-serif;
        }


        &:before {
            content: '';
            background-color: black;
            transform: translateX(-100%);
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0; 
            transition: .3s ease;
        }

          &:hover {
            
            span {
                color: white;
            }

            &:before {
              transform: translateX(0);
            
            }
          }
        
    }
}

 div.content-container-search {

            //padding: 80px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @media only screen and (max-width: 768px) {
                padding: 40px;
            }
            
            div {
                
                /*&:nth-child(1){
                    //width: 50%;

                    @media only screen and (max-width: 561px) {
                        width: 100%;
                    } 
                }*/
                
                &.large {
	                width: 100%;
                }
                
                &.medium {
	                width: 48%;
                    @media screen and (max-width: 411px) {
                        width: 100%;
                    }
                }
                
                
                // float: left;
                
                /*&:first-of-type {
                    padding-right: 50px;
                }*/
                &.filmography_implication{
                    div{
                       width: 100%;
                        display: block;
                        margin-top: 1.5rem;
                        text-align: right;
                        position: absolute;
                        right: 27%;
                        @media only screen and (max-width: 1540px) {
                            position: inherit;
                            margin-top: 5rem;
                            text-align: left;

                            label:nth-child(1){
                                margin-left: 0;
                            }
                        }
                        
                        @media only screen and (max-width: 1200px) {
                               display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                        }


                        label{
                                padding-left: 2rem;
                                position: relative;
                                margin-bottom: 12px;
                                cursor: pointer;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                margin-left: 2rem;
                                @media only screen and (max-width: 1250px) {
                                    font-size: 14px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    display: inline-block;
                                    margin-top: 1rem;
                                    margin-left: 0rem;
                                    margin-right: 2rem;


                                    &:nth-child(3){
                                        margin-left: 0;
                                    }
                                }

                             input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }

                            .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 20px;
                                width: 20px;
                                background-color: #eee;

                                &.checkbox_check{
                                    background-image: linear-gradient(45deg, #308e94 , #3cc5c0);
                                }
                            }

                            .container:hover input ~ .checkmark {
                                background-color: #ccc;
                            } 


                             input:checked ~ .checkmark {
                                background-image: linear-gradient(45deg, #308e94 , #3cc5c0);
                            }

                            .checkmark:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }

                             input:checked ~ .checkmark:after {
                                display: block;
                            }
                        }
                    }
                }
                h3 {
                    margin-top: 30px;
                    margin-bottom: 10px;
                    font-weight: 900;
                    text-transform: uppercase;
                    font-size: 24px;

                     @media only screen and (max-width: 768px) {
                        white-space: initial;
                        font-size: 20px;
                    }

                }

                p {
                    float: left;
                    margin-top: 20px;
                    font-weight: 600;
                    font-style: italic;
                    color: white;
                    font-size: 20px;
                    margin-right: 20px;
                }

                label {
                    color: white;
                    font-size: 16px;
                    font-weight: 300;
                    position: relative;
                    top: 2px;
                }


                select {
                    width: 100%;
                    border: 1px solid rgba(grey, .2);
                    background: white;
                    padding: 15px 40px;
                    //float: left;
                    margin-bottom: 20px;
                    font-family: Raleway,sans-serif;
                    font-weight: 300;
                    display: block;
                    border-radius: 0;
                    height: 50px;
                    color: #797979;
                    @media only screen and (max-width: 768px) {
                        padding: 10px 40px;
                    }
                }

                input[type="text"] {
                    width: 100%;
                    border: 1px solid rgba(grey, .2);
                    padding: 15px 30px;
                    float: left;
                    margin-bottom: 20px;
                    font-family: Raleway,sans-serif;
                    font-weight: 300;
                }

                input[type="radio"] { 
                    margin-top: 20px;
                    margin-left: 10px;
                    margin-right: 10px;
                }

                /*&:nth-child(2) {
                    float: none;
                    clear: both;
                    padding: 0;
                    width: 40%;
                    display: flex;
                    flex-wrap: wrap;

                    @media only screen and (max-width: 561px) {
                       width: 100%;
                       display: block;
                    }

                    p{
                        float: none;
                    }

                }*/
            }
        }

ul.content-container-films {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    margin-top: 100px;

    li {
        background: white;
        width: calc(100% / 3);
        float: left;
        height: 250px;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 30px;
        position: relative;
        transition: .3s ease;
        overflow: hidden;

        @media screen and (max-width: 564px) {
            width: 50%;
        }
        @media screen and (max-width: 450px) {
            padding: 0;
            padding-top: 30px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            width: 100%;
            height: 100%;
            background-image: inherit;
            background-size: cover;
            transition: .5s;
            z-index: 5;
            transform: translate(-50%, -50%) scale(1);
            background-position: center;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            z-index: 8;
            background: linear-gradient(to right, rgba(0,0,0,0.70) 0%,rgba(0,0,0,0) 100%);
        }

        p {
            margin: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            position: relative;
            z-index: 10;
            text-align: center;
            color: white;
        }
    }

    a li:hover::before {
        transform: translate(-50%, -50%) scale(1.05);
    }

}




















