.footer {
	padding: 0 !important;
}

	div.sub-footer {
		position: relative;
		width: 100%;
		left: 0;
		bottom: 0;
		background: #0d0e0e;
		text-align: center;
		padding-bottom: 1em;

		p {
			color: white;
			font-size: 15px;
			padding: 20px;
			margin: 0;
			transition: .3s ease;
			color: #696969;

			a {
				color: #FFF;
				text-decoration: none;
				font-style: italic;
				opacity: .5;
				transition: .3s ease;


				&:hover{
					opacity: 1;
				}
			}
			
		}

		ul {
		 	margin-top: 0;
		 	margin-bottom: 0;
		 	padding: 0;
		 	display: inline;
		 	li { 
		 		display: inline;

		 		&:first-child a{
					padding-right: 20px;
				}
			 	a {
					color: #696969;
					transition: .3s ease;

					&:hover{
						color: #FFF;
					}
				}
			}
		}
		.signature.adjust{
            font-family: Adjust;
		    color: #fff;
		    float: right;
		    transition: .5s;
		    position: absolute;
		    right: 4rem;
		    text-decoration: none;
		    bottom: 50%;
		    transform: translateY(50%) rotate(0deg);

			    @media only screen and (max-width: 414px) {
					font-family: Adjust;
				    color: #fff;
				    -webkit-transition: .5s;
				    transition: .5s;
				    position: absolute;
				    left: 47%;
				    text-decoration: none;
				    bottom: 20%;
				    transform: translate(0%, 50% ) rotate(0deg);
				}
		
			    &:hover{
					transform: translateY(50%) rotate(360deg);
					
					@media only screen and (max-width: 414px) {
					
				   		transform: translate(0%, 50% ) rotate(360deg);
					}
			    }
			}
	}

footer {
	width: 100%;
	position: relative;
	padding: 60px;
	background: #282828;
	padding-bottom: 200px;

	div.next:after {
				transform: translateY(-35%) rotate(135deg) !important;
	}



ul {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: left;

		& {
			 display: flex;
			 flex-direction: row;

			@media screen and (max-width: 768px) {
				display: block;
				text-align: center;
			}
		}

		&:hover a {
			opacity: .5;
		}

		li {
			flex-grow: 1;

			a {
				color: white;
				text-decoration: inherit;
				font-size: 18px;
				font-weight: 800;
				font-style: italic;
				//line-height: 45px;
				transition : .3s ease;
				padding: 12px 0px;
				display: block;
				padding-right: 60px;
				background: rgba(black,0);
				opacity: .75;
				@media screen and (max-width: 768px) {
					padding-right: 0;
				}

				&:hover {
					opacity: 1;
				}
			}

			ul {
				display: initial;

				li {
					a {
						font-weight: 400;
						font-style: initial;
						font-size: 15px;
						
					}
				}
			}
		}
	}
}