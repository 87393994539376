@import url("https://fonts.googleapis.com/css?family=Raleway:200,300i,300,500,600,600i,800i,900,900i");
@font-face {
  font-family: "adjust";
  src: url("../fonts/adjust-font.eot");
  src: url("../fonts/adjust-font.eot?#iefix") format("embedded-opentype"), url("../fonts/adjust-font.woff") format("woff"), url("../fonts/adjust-font.ttf") format("truetype"), url("../fonts/adjust-font.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
}

#tv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  opacity: 0.2;
}

div.showreel {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 12;
  display: none;
}
div.showreel iframe {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  height: 60%;
  width: 40%;
}
@media screen and (max-width: 411px) {
  div.showreel iframe {
    height: 30%;
    width: 90%;
  }
}

ul.breadcrumb {
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  bottom: 83px;
  left: 150px;
}
@media screen and (max-width: 768px) {
  body.inner ul.breadcrumb {
    position: relative;
  }
}
ul.breadcrumb li {
  display: inline-block;
  color: white;
  font-style: italic;
}
ul.breadcrumb li:last-of-type a {
  font-weight: 600;
}
ul.breadcrumb li a {
  text-decoration: none;
  color: white;
  font-style: italic;
}
ul.breadcrumb li a:hover {
  text-decoration: underline;
}

ul.grid {
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
  height: 100%;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  list-style: none;
  pointer-events: none;
}
@media only screen and (max-width: 1600px) {
  ul.grid {
    max-width: calc(100% - 400px);
  }
}
@media only screen and (max-width: 1024px) {
  ul.grid {
    max-width: calc(100% - 100px);
  }
}
@media only screen and (max-width: 700px) {
  ul.grid {
    max-width: calc(100% - 30px);
  }
}
ul.grid li {
  display: inline-block;
  height: 100%;
  width: 16.6666666667%;
  float: left;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
ul.grid li:last-of-type {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
body.inner ul.grid li:nth-child(5), body.inner ul.grid li:nth-child(6) {
  display: none;
}
ul.grid:before {
  content: "";
  width: 105px;
  height: 100%;
  position: absolute;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  top: 0;
  left: -105px;
}
ul.grid:after {
  content: "";
  width: 105px;
  height: 100%;
  position: absolute;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  top: 0;
  right: -105px;
}

.fp-auto-height {
  padding-top: 0 !important;
}

div.section {
  background: black;
  position: relative;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 411px) {
  div.section.scrollable {
    height: 100vh;
  }
}
div.section div.section-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
div.section div.section-background div.section-background-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
@media screen and (max-width: 765px) {
  div.section div.section-background div.section-background-img {
    background-position: center;
  }
}
@media screen and (max-width: 415px) {
  div.section div.section-background div.section-background-img {
    background-attachment: scroll;
  }
}
div.section div.section-background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
}
body.film div.section div.section-background:after {
  content: none;
}
body:not(.inner) div.section div.section-background:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
  pointer-events: none;
}
body.film body:not(.inner) div.section div.section-background:before {
  content: none;
}
div.section div.section-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 700px;
  background: white;
  padding: 80px;
  padding-top: 100px;
  overflow-y: auto;
}
div.section div.section-inner.section-inner_team {
  width: 60%;
}
div.section div.section-inner ul li {
  line-height: 2;
}
div.section div.section-inner .container a {
  color: #2ab6b7;
  text-decoration: none;
}
div.section div.section-inner .container a.link_download {
  color: #000000;
  position: relative;
  transition: 0.2s ease;
}
div.section div.section-inner .container a.link_download:after {
  height: 25px;
  width: 23px;
  content: "";
  top: 0px;
  right: -45px;
  position: absolute;
  background: url("../img/download_black.svg") no-repeat;
}
div.section div.section-inner .container a.link_download:hover {
  color: #2ab6b7;
}
div.section div.section-inner .container a.link_download:hover:after {
  background: url("../img/download_color.svg") no-repeat;
}
@media screen and (max-width: 1400px) {
  div.section div.section-inner {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  div.section div.section-inner {
    width: 100%;
    position: relative;
    overflow: hidden;
    top: initial;
    right: initial;
    height: auto;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-inner {
    padding: 1.5em;
  }
}
div.section div.section-inner h3, div.section div.section-inner h4 {
  text-transform: uppercase;
  font-size: 35px;
}
div.section div.section-inner h4 {
  font-size: 25px;
}
div.section div.section-inner .member p {
  font-weight: 300;
  display: none;
}
div.section div.section-inner .member a {
  font-size: 14px;
  font-style: italic;
  font-weight: 100;
}
div.section div.section-inner .member h4 {
  line-height: 25px;
  font-size: 1em;
  font-weight: 600;
  text-transform: none;
  position: relative;
  transition: 0.3s;
  padding-right: 20px;
  margin-bottom: 5px;
}
div.section div.section-inner .member h4.transform-after:after {
  transform: translateY(4px) rotate(135deg);
}
div.section div.section-inner .member:nth-child(1) h4 {
  cursor: pointer;
}
div.section div.section-inner .member:nth-child(1) h4:hover {
  color: #2ab6b7;
}
div.section div.section-inner .member:nth-child(1) h4:after {
  content: "";
  position: absolute;
  right: 3px;
  display: inline-block;
  top: 4px;
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: black;
  border-left-color: black;
  transition: border-bottom-color 0.2s, border-left-color 0.2s 0.1s, transform 0.4s;
}
div.section div.section-inner .member:nth-child(1) h4:hover:after {
  border-bottom-color: #2ab6b7;
  border-left-color: #2ab6b7;
}
div.section div.section-inner p {
  line-height: 25px;
  font-weight: 300;
}
div.section div.section-inner p a {
  text-decoration: none;
  color: black;
}
div.section div.section-inner p img {
  height: auto !important;
  width: auto !important;
  max-width: 100%;
}
div.section div.section-inner a.next-content {
  position: absolute;
  text-decoration: none;
  color: grey;
  font-style: italic;
  bottom: 80px;
}
div.section div.section-inner div.section-inner-block {
  width: 100%;
  height: 140px;
  background: purple;
  margin-top: 30px;
  padding: 20px;
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
@media screen and (max-width: 1265px) {
  div.section div.section-inner div.section-inner-block {
    height: 180px;
    padding: 20px;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-inner div.section-inner-block {
    height: 160px;
    padding: 20px;
  }
}
div.section div.section-inner div.section-inner-block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
}
div.section div.section-inner div.section-inner-block h4 {
  margin-top: 0;
  text-transform: uppercase;
  color: white;
  font-size: 23px;
  position: relative;
  max-width: 53%;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1265px) {
  div.section div.section-inner div.section-inner-block h4 {
    top: 20%;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-inner div.section-inner-block h4 {
    top: 10%;
    max-width: 100%;
    padding-top: 20px;
  }
}
div.section div.section-inner div.section-inner-block div.cta-inner {
  right: 20px;
  bottom: 40px;
  left: initial;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(121, 121, 121, 0.2392156863);
}
@media screen and (max-width: 1265px) {
  div.section div.section-inner div.section-inner-block div.cta-inner {
    right: inherit;
    left: auto;
    top: 70%;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-inner div.section-inner-block div.cta-inner {
    left: 20px;
    top: 70%;
  }
}
div.section ul.logo_scope {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
  width: auto;
  position: absolute;
  bottom: 0%;
  left: 60px;
  right: 760px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
  z-index: 4;
  transition: 0.2s ease;
}
@media screen and (max-width: 1150px) {
  div.section ul.logo_scope {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1350px) {
  div.section ul.logo_scope {
    right: 670px;
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  div.section ul.logo_scope {
    right: 570px;
    left: 40px;
  }
}
@media screen and (max-width: 1024px) {
  div.section ul.logo_scope {
    right: 570px;
  }
}
@media screen and (max-width: 768px) {
  div.section ul.logo_scope {
    right: 0;
    top: 40%;
    flex-wrap: inherit;
    width: auto;
    left: 40px;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  div.section ul.logo_scope {
    top: 50%;
  }
}
@media screen and (max-width: 490px) {
  div.section ul.logo_scope {
    flex-wrap: wrap;
    height: 120px;
    left: 20px;
    top: 47%;
  }
}
@media screen and (max-width: 320px) {
  div.section ul.logo_scope {
    top: 37%;
  }
}
div.section ul.logo_scope li {
  opacity: 0.5;
  transition: 0.2s ease;
  pointer-events: none;
}
div.section ul.logo_scope li.active-logo {
  opacity: 1;
  transform: scale(1.2);
  transition: 0.2s ease;
}
div.section ul.logo_scope:hover {
  transition: 0.2s ease;
}
div.section ul.logo_scope:hover li:hover {
  opacity: 1;
}
div.section ul.logo_scope li {
  display: inline-block;
  width: calc(25% - 20px);
}
@media screen and (max-width: 1150px) {
  div.section ul.logo_scope li {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 1768px) {
  div.section ul.logo_scope li {
    width: calc(25% - 20px);
  }
}
@media screen and (max-width: 490px) {
  div.section ul.logo_scope li {
    width: calc(50% - 20px);
  }
}
div.section ul.logo_scope img {
  width: 80%;
}
@media screen and (max-width: 1350px) {
  div.section ul.logo_scope img {
    width: 110%;
  }
}
@media screen and (max-width: 768px) {
  div.section ul.logo_scope img {
    width: 80%;
  }
}
@media screen and (max-width: 490px) {
  div.section ul.logo_scope img {
    width: 70%;
  }
}
div.section div.section-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  left: 60px;
  width: 46%;
}
@media screen and (max-width: 1400px) {
  div.section div.section-content {
    left: 40px;
  }
}
@media screen and (max-width: 768px) {
  div.section div.section-content {
    width: 76%;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-content {
    width: 80%;
    left: 20px;
  }
}
@media screen and (max-width: 411px) {
  div.section div.section-content {
    top: 50%;
  }
}
body.film div.section div.section-content {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  width: calc(100% - 700px);
}
@media screen and (max-width: 768px) {
  body.inner div.section div.section-content {
    top: initial;
    transform: none;
    margin-top: 200px;
    position: relative;
    margin-bottom: 150px;
  }
}
div.section div.section-content div.section-content-film img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  max-height: 70%;
  display: block;
  box-shadow: 10px 10px 150px 0px rgba(0, 0, 0, 0.5);
}
div.section div.section-content div.section-content-film:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  height: 120px;
  width: 120px;
  background-image: url("../img/director-chair.svg");
  background-size: cover;
  background-position: 50% 50%;
  margin: 0 auto;
  display: block;
}
div.section div.section-content h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: 85px;
  line-height: 90px;
}
body.home div.section div.section-content h2 {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  div.section div.section-content h2 {
    font-size: 75px;
    line-height: 70px;
  }
}
@media screen and (max-width: 1285px) {
  div.section div.section-content h2 {
    font-size: 61px;
    line-height: 63px;
  }
}
@media screen and (max-width: 1272px) {
  div.section div.section-content h2 {
    font-size: 62px;
    line-height: 60px;
  }
}
@media screen and (max-width: 1200px) {
  div.section div.section-content h2 {
    font-size: 45px;
    line-height: 60px;
  }
}
@media screen and (max-width: 320px) {
  div.section div.section-content h2 {
    font-size: 31px;
    line-height: 32px;
  }
}
div.section div.section-content h3 {
  margin: 0;
  text-transform: uppercase;
  font-size: 75px;
  line-height: 80px;
}
@media screen and (max-width: 1400px) {
  div.section div.section-content h3 {
    font-size: 60px;
    line-height: 65px;
  }
}
@media screen and (max-width: 1024px) {
  div.section div.section-content h3 {
    font-size: 50px;
    line-height: 55px;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-content h3 {
    font-size: 40px;
    line-height: 44px;
  }
}
@media screen and (max-width: 320px) {
  div.section div.section-content h3 {
    font-size: 31px;
    line-height: 32px;
  }
}
div.section div.section-content p {
  font-weight: 300;
  font-style: italic;
  font-size: 22px;
  max-width: 610px;
  line-height: 40px;
}
@media screen and (max-width: 1400px) {
  div.section div.section-content p {
    max-width: 45vw;
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-content p {
    max-width: 65vw;
  }
}
@media screen and (max-width: 768px) {
  body.inner div.section div.section-content p {
    max-width: 80%;
  }
}
@media screen and (max-width: 510px) {
  body.inner div.section div.section-content p {
    max-width: 100%;
  }
}
div.section div.cta {
  position: absolute;
  bottom: 40px;
  height: 50px;
  width: auto;
  border: white 4px solid;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s ease;
  left: 120px;
}
@media screen and (max-width: 1400px) {
  div.section div.cta {
    left: 120px;
  }
}
@media screen and (max-width: 510px) {
  div.section div.cta {
    width: 220px;
  }
}
@media screen and (max-width: 320px) {
  div.section div.cta {
    left: 80px;
  }
}
div.section div.cta span {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0px 29px;
  font-size: 16px;
  position: relative;
  top: 13px;
  z-index: 1;
  transition: 0.3s ease;
}
@media screen and (max-width: 510px) {
  div.section div.cta span {
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 411px) {
  div.section div.cta span {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    padding: 0 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
div.section div.cta:before {
  content: "";
  background-color: white;
  transform: translateX(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
}
div.section div.cta:hover span {
  color: black;
}
div.section div.cta:hover:before {
  transform: translateX(0);
}
div.section div.next {
  position: absolute;
  bottom: 40px;
  left: 60px;
  height: 50px;
  width: 49px;
  border: white 4px solid;
  overflow: hidden;
  transition: 0.3s ease;
}
@media screen and (max-width: 1400px) {
  div.section div.next {
    left: 40px;
  }
}
@media screen and (max-width: 320px) {
  div.section div.next {
    left: 20px;
  }
}
body.inner div.section div.next:after {
  transform: translateY(-50%) rotate(45deg);
}
@media screen and (max-width: 768px) {
  body.inner div.section div.next {
    position: relative;
  }
}
@media screen and (max-width: 411px) {
  body.inner div.section div.next {
    position: absolute;
  }
}
div.section div.next:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-70%) rotate(-45deg);
  height: 15px;
  width: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: white 4px solid;
  border-bottom: white 4px solid;
  transition: 0.3s ease;
}
div.section div.next:before {
  content: "";
  background-color: white;
  transform: translateX(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.3s ease;
}
div.section div.next:hover span {
  color: black;
}
div.section div.next:hover:before {
  transform: translateX(0);
}
div.section div.next:hover:after {
  border-left: black 6px solid;
  border-bottom: black 6px solid;
}

div.infos {
  position: fixed;
  text-align: right;
  color: white;
  right: 40px;
  bottom: 40px;
  z-index: 9;
}
div.infos ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
}
div.infos ul li {
  display: inline-block;
}
div.infos ul li a {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  font-size: 22px;
}
div.infos span {
  display: block;
  font-weight: 300;
  line-height: 20px;
  font-size: 15px;
}

div.nav {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  z-index: 9;
  text-align: right;
}
@media screen and (max-width: 1400px) {
  div.nav {
    right: 40px;
  }
}
@media screen and (max-width: 510px) {
  div.nav {
    display: none;
  }
}
div.nav.inner {
  right: 700px;
}
div.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  display: block;
}
div.nav ul li {
  display: block;
  text-align: right;
}
div.nav ul li.active a {
  opacity: 1;
  padding-right: 85px;
  color: white;
}
div.nav ul li.active a:after {
  right: 30px;
}
div.nav ul li a {
  position: relative;
  display: block;
  text-align: right;
  padding: 20px 0px;
  text-decoration: none;
  color: white;
  font-style: italic;
  font-weight: 300;
  line-height: 3px;
  padding-right: 55px;
  transition: 0.3s ease;
  opacity: 0.5;
  color: rgba(255, 255, 255, 0);
}
div.nav ul li a:hover, div.nav ul li a.active {
  opacity: 1;
  padding-right: 85px;
  color: white;
}
div.nav ul li a:hover:after, div.nav ul li a.active:after {
  right: 30px;
}
div.nav ul li a:after {
  content: "";
  position: absolute;
  right: 0;
  height: 4px;
  width: 40px;
  background: white;
  transition: 0.3s ease;
}

#map-canvas {
  height: 300px;
}

div.section div.section-inner .contact-info .contact-info_adress h4 {
  margin-top: 70px;
}
div.section div.section-inner .contact-info .contact-info_adress p {
  display: inline-block;
  margin-right: 10px;
}
div.section div.section-inner .contact-info .contact-info_adress p:last-child {
  margin-bottom: 50px;
}
div.section div.section-inner .contact-info .contact-info_adress p a {
  color: #2ab6b7;
}
div.section div.section-inner div.section-inner-block.job-block {
  background-image: url("../img/job_recruteCeo.jpg");
}
div.section div.section-inner div.section-inner-block.job-block:nth-child(1) {
  background-image: url("../img/candidature.jpg");
}
div.section div.section-inner div.section-inner-block.job-block:nth-child(2) {
  background-image: url("../img/job_production.jpg");
}
div.section div.section-inner div.section-inner-block.job-block.job-adjoint {
  background-image: url("../img/220127_jobproduction.jpg");
}
div.section div.section-inner div.section-inner-block.job-block:nth-child(3) {
  background-image: url("../img/img_stage.jpg");
}
@media screen and (max-width: 1264px) {
  div.section div.section-inner div.section-inner-block.job-block h4 {
    max-width: 83%;
  }
}
@media screen and (max-width: 560px) {
  div.section div.section-inner div.section-inner-block.job-block h4 {
    max-width: 93%;
    font-size: 20px;
  }
}

.section-inner form {
  overflow: hidden;
  margin: 35px 0px;
}
.section-inner form input {
  width: calc(50% - 15px);
  padding: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  border: none;
  border-bottom: 1px solid black;
  float: left;
  font-family: Raleway, sans-serif;
}
.section-inner form input[type=checkbox] {
  float: left;
  clear: both;
  padding: 10px;
  width: 20px;
  margin-top: 20px;
}
.section-inner form input.wide {
  width: calc(100% - 15px);
}
.section-inner form label.info_gdpr {
  display: block;
  float: left;
  width: calc(100% - 50px);
  font-size: 12px;
  margin-top: 20px;
}
.section-inner form button.btn_contact {
  border: 4px solid black;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 35px;
  text-decoration: none;
  color: inherit;
  font-weight: 800;
  text-transform: uppercase;
  background: white;
  position: relative;
  overflow: hidden;
  float: left;
}
.section-inner form button.btn_contact span {
  position: relative;
  color: #000;
  font-family: Raleway, sans-serif;
}
.section-inner form button.btn_contact:before {
  content: "";
  background-color: black;
  transform: translateX(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}
.section-inner form button.btn_contact:hover span {
  color: white;
}
.section-inner form button.btn_contact:hover:before {
  transform: translateX(0);
}

div.content-container-search {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  div.content-container-search {
    padding: 40px;
  }
}
div.content-container-search div {
  /*&:nth-child(1){
      //width: 50%;

      @media only screen and (max-width: 561px) {
          width: 100%;
      } 
  }*/
  /*&:first-of-type {
      padding-right: 50px;
  }*/
  /*&:nth-child(2) {
      float: none;
      clear: both;
      padding: 0;
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      @media only screen and (max-width: 561px) {
         width: 100%;
         display: block;
      }

      p{
          float: none;
      }

  }*/
}
div.content-container-search div.large {
  width: 100%;
}
div.content-container-search div.medium {
  width: 48%;
}
@media screen and (max-width: 411px) {
  div.content-container-search div.medium {
    width: 100%;
  }
}
div.content-container-search div.filmography_implication div {
  width: 100%;
  display: block;
  margin-top: 1.5rem;
  text-align: right;
  position: absolute;
  right: 27%;
}
@media only screen and (max-width: 1540px) {
  div.content-container-search div.filmography_implication div {
    position: inherit;
    margin-top: 5rem;
    text-align: left;
  }
  div.content-container-search div.filmography_implication div label:nth-child(1) {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  div.content-container-search div.filmography_implication div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
div.content-container-search div.filmography_implication div label {
  padding-left: 2rem;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2rem;
}
@media only screen and (max-width: 1250px) {
  div.content-container-search div.filmography_implication div label {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1200px) {
  div.content-container-search div.filmography_implication div label {
    display: inline-block;
    margin-top: 1rem;
    margin-left: 0rem;
    margin-right: 2rem;
  }
  div.content-container-search div.filmography_implication div label:nth-child(3) {
    margin-left: 0;
  }
}
div.content-container-search div.filmography_implication div label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
div.content-container-search div.filmography_implication div label .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
div.content-container-search div.filmography_implication div label .checkmark.checkbox_check {
  background-image: linear-gradient(45deg, #308e94, #3cc5c0);
}
div.content-container-search div.filmography_implication div label .container:hover input ~ .checkmark {
  background-color: #ccc;
}
div.content-container-search div.filmography_implication div label input:checked ~ .checkmark {
  background-image: linear-gradient(45deg, #308e94, #3cc5c0);
}
div.content-container-search div.filmography_implication div label .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
div.content-container-search div.filmography_implication div label input:checked ~ .checkmark:after {
  display: block;
}
div.content-container-search div h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  div.content-container-search div h3 {
    white-space: initial;
    font-size: 20px;
  }
}
div.content-container-search div p {
  float: left;
  margin-top: 20px;
  font-weight: 600;
  font-style: italic;
  color: white;
  font-size: 20px;
  margin-right: 20px;
}
div.content-container-search div label {
  color: white;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  top: 2px;
}
div.content-container-search div select {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  background: white;
  padding: 15px 40px;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  display: block;
  border-radius: 0;
  height: 50px;
  color: #797979;
}
@media only screen and (max-width: 768px) {
  div.content-container-search div select {
    padding: 10px 40px;
  }
}
div.content-container-search div input[type=text] {
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  padding: 15px 30px;
  float: left;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  font-weight: 300;
}
div.content-container-search div input[type=radio] {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

ul.content-container-films {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  margin-top: 100px;
}
ul.content-container-films li {
  background: white;
  width: 33.3333333333%;
  float: left;
  height: 250px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px;
  position: relative;
  transition: 0.3s ease;
  overflow: hidden;
}
@media screen and (max-width: 564px) {
  ul.content-container-films li {
    width: 50%;
  }
}
@media screen and (max-width: 450px) {
  ul.content-container-films li {
    padding: 0;
    padding-top: 30px;
  }
}
ul.content-container-films li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  transition: 0.5s;
  z-index: 5;
  transform: translate(-50%, -50%) scale(1);
  background-position: center;
}
ul.content-container-films li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  z-index: 8;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
ul.content-container-films li p {
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  z-index: 10;
  text-align: center;
  color: white;
}
ul.content-container-films a li:hover::before {
  transform: translate(-50%, -50%) scale(1.05);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 60px;
  z-index: 10;
  padding-bottom: 0;
  pointer-events: none;
  height: 100px;
  z-index: 100;
}
header > * {
  pointer-events: auto;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.53), transparent 95%);
}
header h1 {
  text-indent: -9999px;
}
header .logo img {
  position: absolute;
  top: 38px;
  left: 40px;
  width: 160px;
}
@media screen and (max-width: 1400 px) {
  header .logo img {
    left: 40px;
    top: 40px;
  }
}
@media screen and (max-width: 510px) {
  header .logo img {
    width: 120px;
    top: 30px;
    left: 20px;
  }
}
header .languages {
  position: fixed;
  top: 5.3%;
  right: 130px;
  margin: 0;
  padding: 0;
  transition: 0.3s ease;
}
@media screen and (max-width: 414px) {
  header .languages {
    top: 30px;
    right: 85px;
    text-align: right;
  }
}
.inner header .languages {
  top: 4.9%;
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .inner header .languages {
    top: 3.8%;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .inner header .languages {
    top: 30px;
  }
}
@media screen and (max-width: 560px) {
  .inner header .languages {
    position: absolute;
  }
}
@media screen and (max-width: 414px) {
  .inner header .languages {
    top: 30px;
    right: 85px;
    text-align: right;
  }
}

@media screen and (min-width: 1400px) {
  header .languages {
    top: 40px;
    right: 140px;
  }
}
header .languages li {
  display: inline-block;
  color: #CCC;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  transition: 0.4s;
}
header .languages li.active, header .languages li:hover {
  color: white;
  text-decoration: underline;
}
.inner header .languages li.active, .inner header .languages li:hover {
  color: black;
}

@media screen and (max-width: 480px) {
  header .languages li.active, header .languages li:hover {
    color: #CCC !important;
    text-decoration: underline;
  }
}
header .languages li a {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em 0.5em;
  color: #FFF;
}
@media screen and (max-width: 430px) {
  header .languages li a {
    font-size: 12px;
  }
}

div.burger-background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
}

div.burger-content {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 450px;
  background: white;
  padding: 60px 0px;
  transform: translateX(500px);
  transition: 0.5s ease;
  padding-top: 150px;
  z-index: 10;
  overflow-y: scroll;
}
div.burger-content li.active > a {
  color: #2ab6b7;
  opacity: 1;
}
@media only screen and (max-width: 1400px) {
  div.burger-content {
    padding-top: 130px;
  }
}
@media only screen and (max-width: 414px) {
  div.burger-content {
    padding-top: 90px;
  }
}
body.menu-open div.burger-content {
  transform: translateX(0px);
}
div.burger-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}
div.burger-content ul:hover a {
  opacity: 0.5;
}
div.burger-content ul li a {
  color: inherit;
  text-decoration: inherit;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  transition: 0.3s ease;
  padding: 8px 0px;
  display: block;
  padding-right: 60px;
  background: rgba(0, 0, 0, 0);
}
@media only screen and (max-width: 1400px) {
  div.burger-content ul li a {
    font-size: 18px;
    padding: 1.2vh 0px;
    padding-right: 60px;
  }
}
@media only screen and (max-width: 510px) {
  div.burger-content ul li a {
    padding-right: 30px;
  }
}
div.burger-content ul li a:hover {
  padding-right: 70px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.05);
}
div.burger-content ul li ul li a {
  font-weight: 400;
  font-style: initial;
}

.burger-menu {
  position: absolute;
  top: 43px;
  right: 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  z-index: 11;
  transition: 0.3s ease;
}
@media screen and (max-width: 1400px) {
  .burger-menu {
    right: 40px;
    top: 25%;
  }
}
@media screen and (max-width: 510px) {
  .burger-menu {
    right: 20px;
    top: 45%;
  }
}
@media screen and (max-width: 414px) {
  .burger-menu {
    top: 3%;
    position: fixed;
  }
}
body.mini-burger .burger-menu {
  top: 20px;
  right: 20px;
  width: 40px;
  height: 35px;
}

body.mini-burger header ul.languages {
  top: 15px;
  right: 90px;
}

.burger {
  position: absolute;
  background: white;
  width: 80%;
  height: 4px;
  top: 50%;
  right: 0px;
  margin-top: -5px;
  opacity: 1;
}
body.inner:not(.filmography) .burger-menu.menu-on .burger {
  background: white;
}

.burger::before {
  position: absolute;
  background: white;
  width: 100%;
  height: 4px;
  top: 15px;
  content: "";
  display: block;
}
body.mini-burger .burger::before {
  top: 12px;
}

.burger::after {
  position: absolute;
  background: white;
  width: 100%;
  height: 4px;
  bottom: 15px;
  content: "";
  display: block;
}
body.mini-burger .burger::after {
  bottom: 12px;
}

.burger::after, .burger::before, .burger {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.burger-menu.menu-on .burger::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  bottom: 0px;
  background-color: #000;
}

.burger-menu.menu-on .burger::before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 0px;
  background-color: #000;
}

.burger-menu.menu-on .burger {
  background: rgba(111, 111, 111, 0);
}

body.inner:not(.filmography) header .burger-menu .burger {
  background-color: #000;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  body.inner:not(.filmography) header .burger-menu .burger {
    background-color: #FFF;
  }
}
@media screen and (max-width: 414px) {
  body.inner:not(.filmography) header .burger-menu .burger {
    background-color: #FFF;
  }
}
body.inner:not(.filmography) header .burger-menu .burger:before, body.inner:not(.filmography) header .burger-menu .burger:after {
  background-color: inherit;
}

body.inner:not(.filmography) header .burger-menu.menu-on .burger {
  background-color: #FFF;
}
body.inner:not(.filmography) header .burger-menu.menu-on .burger:before, body.inner:not(.filmography) header .burger-menu.menu-on .burger:after {
  background-color: #000;
}
@media screen and (max-width: 768px) {
  body.inner:not(.filmography) header .burger-menu.menu-on .burger {
    background-color: #FFF;
  }
  body.inner:not(.filmography) header .burger-menu.menu-on .burger:before, body.inner:not(.filmography) header .burger-menu.menu-on .burger:after {
    background-color: #000;
  }
}

.footer {
  padding: 0 !important;
}

div.sub-footer {
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #0d0e0e;
  text-align: center;
  padding-bottom: 1em;
}
div.sub-footer p {
  color: white;
  font-size: 15px;
  padding: 20px;
  margin: 0;
  transition: 0.3s ease;
  color: #696969;
}
div.sub-footer p a {
  color: #FFF;
  text-decoration: none;
  font-style: italic;
  opacity: 0.5;
  transition: 0.3s ease;
}
div.sub-footer p a:hover {
  opacity: 1;
}
div.sub-footer ul {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline;
}
div.sub-footer ul li {
  display: inline;
}
div.sub-footer ul li:first-child a {
  padding-right: 20px;
}
div.sub-footer ul li a {
  color: #696969;
  transition: 0.3s ease;
}
div.sub-footer ul li a:hover {
  color: #FFF;
}
div.sub-footer .signature.adjust {
  font-family: Adjust;
  color: #fff;
  float: right;
  transition: 0.5s;
  position: absolute;
  right: 4rem;
  text-decoration: none;
  bottom: 50%;
  transform: translateY(50%) rotate(0deg);
}
@media only screen and (max-width: 414px) {
  div.sub-footer .signature.adjust {
    font-family: Adjust;
    color: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    left: 47%;
    text-decoration: none;
    bottom: 20%;
    transform: translate(0%, 50%) rotate(0deg);
  }
}
div.sub-footer .signature.adjust:hover {
  transform: translateY(50%) rotate(360deg);
}
@media only screen and (max-width: 414px) {
  div.sub-footer .signature.adjust:hover {
    transform: translate(0%, 50%) rotate(360deg);
  }
}

footer {
  width: 100%;
  position: relative;
  padding: 60px;
  background: #282828;
  padding-bottom: 200px;
}
footer div.next:after {
  transform: translateY(-35%) rotate(135deg) !important;
}
footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
footer ul {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  footer ul {
    display: block;
    text-align: center;
  }
}
footer ul:hover a {
  opacity: 0.5;
}
footer ul li {
  flex-grow: 1;
}
footer ul li a {
  color: white;
  text-decoration: inherit;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
  transition: 0.3s ease;
  padding: 12px 0px;
  display: block;
  padding-right: 60px;
  background: rgba(0, 0, 0, 0);
  opacity: 0.75;
}
@media screen and (max-width: 768px) {
  footer ul li a {
    padding-right: 0;
  }
}
footer ul li a:hover {
  opacity: 1;
}
footer ul li ul {
  display: initial;
}
footer ul li ul li a {
  font-weight: 400;
  font-style: initial;
  font-size: 15px;
}

.section-background .section-background-img-filmo {
  filter: blur(8px);
}
.section-background img {
  height: 60%;
  position: absolute;
  top: 50%;
  left: calc((100% - 700px) / 2);
  transform: translate(-50%, -50%);
  z-index: 100;
}
@media screen and (max-width: 768px) {
  .section-background img {
    transform: translate(0);
    display: block;
    position: initial;
    margin: 0 auto;
    height: 200px;
  }
}
@media screen and (max-width: 1024px) and (orientation: portrait) {
  .section-background img {
    height: 40%;
    left: calc((100% - 500px) / 2);
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .section-background img {
    left: calc((100% - 490px) / 2);
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .section-background img {
    left: calc((100% - 500px) / 2);
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  .section-background img {
    height: 330px;
  }
}

div.section div.section-inner a.btn_back {
  color: #000;
  text-decoration: none;
  position: relative;
  transition: 0.2s;
  position: absolute;
  top: 60px;
}
div.section div.section-inner a.btn_back:before {
  content: "";
  position: absolute;
  left: -15px;
  display: inline-block;
  top: 45%;
  width: 7px;
  height: 7px;
  transform: rotate(45deg) translateX(-50%);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: black;
  border-left-color: black;
  transition: 0.2s ease-in;
}
@media screen and (max-width: 414px) {
  div.section div.section-inner a.btn_back:before {
    left: -9px;
  }
}
div.section div.section-inner a.btn_back:hover {
  color: #2ab6b7;
}
div.section div.section-inner a.btn_back:hover:before {
  border-bottom-color: #2ab6b7;
  border-left-color: #2ab6b7;
  left: -20px;
}
@media screen and (max-width: 414px) {
  div.section div.section-inner a.btn_back:hover:before {
    left: -9px;
  }
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  div.section div.section-inner a.btn_back {
    top: 30px;
  }
}
@media screen and (max-width: 510px) {
  div.section div.section-inner a.btn_back {
    top: 30px;
  }
}
@media screen and (max-width: 414px) {
  div.section div.section-inner a.btn_back {
    top: 20px;
  }
}
div.section div.section-inner div p span {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  div.section div.section-inner {
    top: 20%;
    padding-top: 3em;
  }
}
@media screen and (max-width: 414px) {
  div.section div.section-inner {
    padding-top: 3em;
  }
}
div.section div.section-inner .container h2 {
  text-transform: uppercase;
}
div.section div.section-inner .container h3 {
  font-size: 25px;
}
div.section div.section-inner .container .movie_category {
  display: inline-block;
  padding: 0;
  list-style: none;
}
div.section div.section-inner .container .movie_category li span {
  font-weight: 300;
}
div.section div.section-inner .container .film-information div {
  margin-bottom: 1.5em;
  position: relative;
  margin-left: 35px;
}
div.section div.section-inner .container .film-information div:before {
  height: 25px;
  width: 23px;
  content: "";
  top: 0px;
  left: -35px;
  position: absolute;
}
div.section div.section-inner .container .film-information div:first-child p {
  display: inline-block;
  margin: 0;
}
div.section div.section-inner .container .film-information div:first-child:before {
  background: url("../img/year.svg") no-repeat;
}
div.section div.section-inner .container .film-information div:nth-child(2):before {
  background: url("../img/format.svg") no-repeat;
}
div.section div.section-inner .container .film-information div:nth-child(3):before {
  background: url("../img/genre.svg") no-repeat;
}
div.section div.section-inner .container .film-information div:nth-child(4):before {
  background: url("../img/director-chair.svg") no-repeat;
}
div.section div.section-inner .container .film-information div:last-child:before {
  background: url("../img/avatar.svg") no-repeat;
}
div.section div.section-inner .container .film-information div span {
  font-weight: 600;
}
div.section div.section-inner .container .film-information ul {
  display: inline;
  padding: 0;
}
div.section div.section-inner .container .film-information ul li {
  display: inline-block;
}
div.section div.section-inner .container .film-information ul li span {
  font-weight: 100;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2500;
  background: rgba(0, 0, 0, 0.8);
}
.modal .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  background: url(../../dist/img/modal_fond.jpg) no-repeat;
  background-size: cover;
  min-height: 60vh;
  width: 50%;
  padding: 2em;
  background-position: center;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .modal .content {
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .modal .content {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .modal .content {
    width: 90%;
    height: 70vh;
  }
}
@media only screen and (max-width: 460px) {
  .modal .content {
    width: 90%;
    height: 90vh;
  }
}
@media only screen and (max-width: 375px) {
  .modal .content {
    width: 90%;
    height: 90vh;
  }
}
@media only screen and (max-width: 360px) {
  .modal .content {
    width: 90%;
    height: 90vh;
  }
}
@media only screen and (max-width: 320px) {
  .modal .content {
    width: 90%;
    height: 90vh;
  }
}
.modal .content a.close {
  color: #000;
  position: absolute;
  right: 5%;
  top: 7%;
  cursor: pointer;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .modal .content a.close {
    right: 0px;
  }
}
@media only screen and (max-width: 460px) {
  .modal .content a.close {
    right: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .modal .content a.close {
    right: 15px;
  }
}
.modal .content a.close span {
  height: 2px;
  width: 30px;
  background-color: transparent;
  display: block;
  position: absolute;
  right: 5%;
  top: 44%;
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .modal .content a.close span {
    right: 45%;
  }
}
@media only screen and (max-width: 510px) {
  .modal .content a.close span {
    right: 0;
  }
}
.modal .content a.close span:before {
  height: 2px;
  width: 30px;
  background-color: #FFF;
  position: absolute;
  top: -10px;
  right: 0;
  content: " ";
  transition: all 0.2s ease-in-out;
  transform: rotate(45deg) translate(5px, 9px);
}
.modal .content a.close span:after {
  height: 2px;
  width: 30px;
  background-color: #FFF;
  position: absolute;
  top: 10px;
  right: 0;
  content: " ";
  transition: all 0.2s ease-in-out;
  transform: rotate(-45deg) translate(5px, -9px);
}
.modal .content h3 {
  color: #FFF;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 60px;
  font-family: Raleway, sans-serif;
  font-weight: 900;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 0px;
  margin-left: 5%;
  margin-top: 2em;
  width: 60%;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .modal .content h3 {
    margin: 2em 0 0px 5%;
    font-size: 50px;
    width: 40%;
  }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .modal .content h3 {
    margin: 3em 0 40px 5%;
  }
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .modal .content h3 {
    margin: 2.7em 0 40px 5%;
  }
}
@media only screen and (max-width: 414px) {
  .modal .content h3 {
    margin: 2.5em 0 0;
  }
}
@media only screen and (max-width: 375px) {
  .modal .content h3 {
    margin: 4.5em 0 0;
    font-size: 41px;
    width: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .modal .content h3 {
    margin: 2.5em 0 0px 0%;
    font-size: 30px;
  }
}
.modal .content p {
  font-size: 1em;
  margin-left: 5%;
  width: 40%;
  color: #FFF;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .modal .content p {
    margin: 0 0 25px 5%;
  }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .modal .content p {
    margin-left: 5%;
    width: 80%;
  }
}
@media only screen and (max-width: 414px) {
  .modal .content p {
    margin-left: 0;
    width: 100%;
  }
}
.modal .content a {
  text-decoration: none;
  color: #FFF;
  margin-bottom: 2rem;
}
.modal .content a:last-child {
  margin-left: 5%;
  position: relative;
}
.modal .content a:last-child:after {
  content: "\f39e";
  font-family: "Font Awesome 5 Brands";
  right: -13%;
  position: absolute;
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .modal .content a:last-child {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .modal .content a:last-child {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .modal .content a:last-child {
    margin-left: 5%;
  }
}
@media only screen and (max-width: 414px) {
  .modal .content a:last-child {
    margin-left: 0;
  }
}

.modal#modale-newsletter a.close {
  z-index: 10;
  color: #000;
  cursor: pointer;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
@media only screen and (max-width: 460px) {
  .modal#modale-newsletter a.close {
    position: absolute;
    top: 7%;
    right: 7%;
  }
}
.modal#modale-newsletter a.close span {
  height: 2px;
  width: 30px;
  background-color: transparent;
  display: block;
  position: absolute;
  right: 5%;
  top: 10%;
}
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .modal#modale-newsletter a.close span {
    top: 5%;
  }
}
.modal#modale-newsletter a.close span:before {
  height: 2px;
  width: 30px;
  background-color: #FFF;
  position: absolute;
  top: -10px;
  right: 0;
  content: " ";
  transition: all 0.2s ease-in-out;
  transform: rotate(45deg) translate(5px, 9px);
}
.modal#modale-newsletter a.close span:after {
  height: 2px;
  width: 30px;
  background-color: #FFF;
  position: absolute;
  top: 10px;
  right: 0;
  content: " ";
  transition: all 0.2s ease-in-out;
  transform: rotate(-45deg) translate(5px, -9px);
}
.modal#modale-newsletter div.content#modale-newsletter-content {
  display: flex;
  min-height: fit-content;
  height: fit-content;
  width: 75%;
  background-image: none;
  background-color: white;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  .modal#modale-newsletter div.content#modale-newsletter-content {
    flex-direction: column;
    height: max-content;
  }
}
@media only screen and (max-width: 460px) {
  .modal#modale-newsletter div.content#modale-newsletter-content {
    width: 90%;
  }
}
.modal#modale-newsletter div.content#modale-newsletter-content div.img-container {
  background-size: cover;
  background-position: center;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .modal#modale-newsletter div.content#modale-newsletter-content div.img-container {
    height: 20vh;
  }
}
@media only screen and (max-width: 460px) {
  .modal#modale-newsletter div.content#modale-newsletter-content div.img-container {
    height: 15vh;
  }
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container h3 {
  color: #000;
  width: auto;
  font-size: 30px;
  margin: 0;
}
@media only screen and (max-width: 460px) {
  .modal#modale-newsletter div.content#modale-newsletter-content div.form-container h3 {
    font-size: 20px;
  }
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container p {
  color: #000;
  margin: 0;
  margin-bottom: 20px;
  width: auto;
}
@media only screen and (max-width: 460px) {
  .modal#modale-newsletter div.content#modale-newsletter-content div.form-container p {
    font-size: 14px;
  }
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form {
  overflow: hidden;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form input {
  width: calc(50% - 15px);
  padding: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  border: none;
  border-bottom: 1px solid black;
  float: left;
  font-family: Raleway, sans-serif;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form input[type=checkbox] {
  float: left;
  clear: both;
  padding: 10px;
  width: 20px;
  margin-top: 20px;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form input.wide {
  width: calc(100% - 15px);
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form label.info_gdpr {
  display: block;
  float: left;
  width: calc(100% - 50px);
  font-size: 12px;
  margin-top: 20px;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form label.info_gdpr a {
  color: #2ab6b7;
  text-decoration: none;
  margin: 0;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form label.info_gdpr a::after {
  content: none;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form button.btn_contact {
  border: 4px solid black;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 35px;
  text-decoration: none;
  color: inherit;
  font-weight: 800;
  text-transform: uppercase;
  background: white;
  position: relative;
  overflow: hidden;
  float: left;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form button.btn_contact span {
  position: relative;
  color: #000;
  font-family: Raleway, sans-serif;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form button.btn_contact:before {
  content: "";
  background-color: black;
  transform: translateX(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form button.btn_contact:hover span {
  color: white;
}
.modal#modale-newsletter div.content#modale-newsletter-content div.form-container form button.btn_contact:hover:before {
  transform: translateX(0);
}

/* MODAL GPDR */
.modal-gdpr {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 25000;
  background: rgba(0, 0, 0, 0.8);
  display: none;
}
.modal-gdpr h4 {
  font-size: 2rem;
  margin-top: 0.5rem;
  position: relative;
  padding-bottom: 1.5rem;
}
.modal-gdpr h4:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 90px;
  background-color: #000;
}
.modal-gdpr .text-intro {
  font-size: 1rem;
  margin-bottom: 3rem;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  max-height: 90vh;
  width: 75%;
  max-width: 1200px;
  padding: 2em;
  overflow-y: auto;
}
@media screen and (max-width: 1200px) {
  .modal-dialog {
    width: 90%;
  }
}
.modal-dialog .modal-content .modal-header button.close {
  border: none;
  font-size: 48px;
  color: #000;
  padding-right: 0;
  position: absolute;
  right: 3%;
  top: 4%;
  cursor: pointer;
  z-index: 30;
  background-color: transparent;
  transition: 0.3s ease;
}
.modal-dialog .modal-content .modal-header button.close:hover {
  transform: scale(0.8);
}
.modal-dialog .modal-footer a.btn-save {
  border: 4px solid black;
  padding: 15px 30px;
  display: inline-block;
  margin-top: 35px;
  text-decoration: none;
  color: inherit;
  font-weight: 800;
  text-transform: uppercase;
  background: white;
  position: relative;
  float: right;
  overflow: hidden;
}
.modal-dialog .modal-footer a.btn-save:before {
  content: "";
  background-color: black;
  transform: translateX(-100%);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease;
}
.modal-dialog .modal-footer a.btn-save:hover span {
  color: white;
  position: relative;
}
.modal-dialog .modal-footer a.btn-save:hover:before {
  transform: translateX(0);
}

.category-container {
  margin-left: -15px;
}

.category {
  width: 25%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}
.category.active .category-title .select-item-btn button.btn-yes {
  background-color: #2ab6b7;
  color: #FFF;
  border: none;
}
.category.active .category-title .select-item-btn button.btn-no {
  background: transparent;
  color: #333;
  border: 1px solid #ccc;
}
@media screen and (max-width: 765px) {
  .category {
    width: 100%;
  }
}
.category .select-item-btn {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  display: flex;
  flex-wrap: wrap;
  float: right;
  width: 63px;
  margin-left: 20px;
}
.category .select-item-btn .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 3px 6px;
  line-height: 1.428571429;
  user-select: none;
  border: 1px solid #ccc;
  width: 50%;
  margin-left: -1px;
  font-size: 10px;
  height: 25px;
}
.category .select-item-btn .btn-yes {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-left: -5px;
  border-right: 1px solid transparent;
}
.category .select-item-btn .btn-no {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 1px solid transparent;
  background-color: #2ab6b7;
  color: #FFF;
  border: none;
}
.category .category-title {
  margin-bottom: 2rem;
  font-size: 13px;
}
.category .category-title h5 {
  font-weight: 700;
  margin-bottom: 1.5rem;
  display: inline;
  font-size: 13px;
}
.category .category-description {
  font-size: 11px;
  text-align: justify;
}
@media screen and (max-width: 765px) {
  .category .category-description {
    margin-bottom: 1rem;
  }
}

.cookie-bar {
  position: fixed;
  width: 100%;
  padding: 15px;
  left: 0;
  z-index: 2500;
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
}
.cookie-bar p {
  color: #FFF;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
  margin: 10px 50px;
}
.cookie-bar div {
  text-align: center;
  display: block;
  margin: 0 auto;
}
.cookie-bar div a {
  color: #FFF;
  padding-bottom: 5px;
  font-weight: 700;
  margin-top: 10px;
  font-size: 12px;
  display: inline-block;
}
.cookie-bar div a.accept_cookie {
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: #fff;
  background-color: #2ab6b7;
  background-size: 200% 100%;
  padding: 10px 30px;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  transition: 0.3s ease;
  background-position: 0 0;
}
.cookie-bar div a.accept_cookie:hover {
  background-position: 100% 0;
}
.cookie-bar div a:first-child {
  margin-right: 50px;
}
@media screen and (max-width: 411px) {
  .cookie-bar div a:first-child {
    margin-right: 0px;
  }
}

.show {
  display: block;
}

.noshow {
  display: none;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.home h2:first-of-type {
  max-width: 1024px;
}
@media screen and (max-width: 1400px) {
  .home h2:first-of-type {
    max-width: 720px;
  }
}
@media screen and (max-width: 1200px) {
  .home h2:first-of-type {
    max-width: 600px;
  }
}
@media screen and (max-width: 800px) {
  .home h2:first-of-type {
    max-width: 500px;
  }
}

h3.news-h3 {
  width: 25%;
}
@media screen and (max-width: 510px) {
  h3.news-h3 {
    width: unset;
  }
}

div.section-inner-block.section-inner-block-front {
  width: 30%;
  height: 330px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 20px;
}
@media screen and (max-width: 510px) {
  div.section-inner-block.section-inner-block-front {
    margin-top: 0;
    width: auto;
  }
}
div.section-inner-block.section-inner-block-front h4 {
  font-size: 28px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 30px;
}
div.section-inner-block.section-inner-block-front span.date {
  color: #fff;
  position: relative;
  margin-bottom: 10px;
  font-size: 24px;
}
div.section-inner-block.section-inner-block-front p {
  margin-top: 10px;
  font-size: 18px !important;
  line-height: inherit !important;
}
div.section-inner-block.section-inner-block-front a {
  text-decoration: none;
}
div.section-inner-block.section-inner-block-front a div.cta.cta-inner {
  right: 0;
  left: unset;
  position: relative;
  bottom: 10px;
  width: fit-content;
}

div.section-content.news-section-content {
  z-index: 1;
  width: 90% !important;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 510px) {
  div.section-content.news-section-content {
    align-items: start;
    flex-direction: column;
  }
}
@media screen and (max-width: 510px) {
  div.section-content.news-section-content div:nth-of-type(even) {
    display: none;
  }
}

.filmography div.section {
  height: 100%;
}
.filmography div.section .section-content {
  top: 2%;
}
.filmography div.section .section-content h1 {
  margin: 0;
  text-transform: uppercase;
  font-size: 95px;
  line-height: 90px;
}
@media screen and (max-width: 1400px) {
  .filmography div.section .section-content h1 {
    font-size: 65px;
  }
}
@media screen and (max-width: 1200px) {
  .filmography div.section .section-content h1 {
    font-size: 55px;
  }
}
@media screen and (max-width: 450px) {
  .filmography div.section .section-content h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 320px) {
  .filmography div.section .section-content h1 {
    font-size: 35px;
  }
}
.filmography div.section > ul:not(.breadcrumb) {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 50vh;
  right: 60px;
  padding-top: 3em;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .filmography div.section > ul:not(.breadcrumb) {
    top: 20vh;
    padding-top: 0;
  }
}
@media screen and (max-width: 414px) {
  .filmography div.section > ul:not(.breadcrumb) {
    top: 9vh;
  }
}
@media screen and (max-width: 660px) {
  .filmography div.section > ul:not(.breadcrumb) {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    padding-top: 5em;
    margin: 0 auto;
    display: block;
    width: 320px;
    left: 50%;
    transform: translate(-50%);
  }
}
.filmography div.section > ul:not(.breadcrumb) li {
  display: flex;
  color: white;
}
@media screen and (max-width: 414px) {
  .filmography div.section > ul:not(.breadcrumb) li {
    display: inline;
  }
}
.filmography div.section > ul:not(.breadcrumb) li ul {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 230px;
}
@media screen and (max-width: 1400px) {
  .filmography div.section > ul:not(.breadcrumb) li ul {
    max-width: 200px;
  }
}
@media screen and (max-width: 768px) {
  .filmography div.section > ul:not(.breadcrumb) li ul {
    max-width: 140px;
  }
}
@media screen and (max-width: 660px) {
  .filmography div.section > ul:not(.breadcrumb) li ul {
    max-width: 155px;
    min-width: 155px;
  }
}
@media screen and (max-width: 414px) {
  .filmography div.section > ul:not(.breadcrumb) li ul {
    max-width: 99px;
    min-width: 95px;
  }
}
.filmography div.section > ul:not(.breadcrumb) li ul li {
  display: block;
  text-align: center;
  padding: 0px 20px;
}
@media screen and (max-width: 1400px) {
  .filmography div.section > ul:not(.breadcrumb) li ul li {
    padding: 0px 5px;
  }
}
.filmography div.section > ul:not(.breadcrumb) li ul li img {
  max-height: 100px;
}
@media screen and (max-width: 414px) {
  .filmography div.section > ul:not(.breadcrumb) li ul li img {
    max-height: 89px;
  }
}
.filmography div.section > ul:not(.breadcrumb) li ul li span {
  font-size: 70px;
  font-weight: 800;
}
@media screen and (max-width: 1400px) {
  .filmography div.section > ul:not(.breadcrumb) li ul li span {
    font-size: 40px;
  }
}
.filmography div.section > ul:not(.breadcrumb) li ul li p {
  font-size: 20px;
}
@media screen and (max-width: 1400px) {
  .filmography div.section > ul:not(.breadcrumb) li ul li p {
    font-size: 16px;
  }
}
.filmography div.section div.section-background {
  position: fixed;
  background-color: #1c1e1e;
}
.filmography div.section div.section-background div.section-background-img {
  background-attachment: initial;
  background-position: 50% 0%;
  background-size: 100%;
}
@media screen and (max-width: 1024px) {
  .filmography div.section div.section-background div.section-background-img {
    background-size: cover;
  }
}
.filmography div.section div.section-inner-filmography {
  position: relative;
  margin: 0 auto;
  max-width: 1250px;
  color: white;
  padding-top: 86vh;
}
@media screen and (max-width: 1400px) {
  .filmography div.section div.section-inner-filmography {
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media screen and (max-width: 768px) {
  .filmography div.section div.section-inner-filmography {
    padding-top: 20vh;
  }
}
@media screen and (max-width: 414px) {
  .filmography div.section div.section-inner-filmography {
    padding-top: 50vh;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 320px) {
  .filmography div.section div.section-inner-filmography {
    padding-top: 63vh;
  }
}
@media screen and (max-width: 768px) {
  .filmography div.section div.section-inner-filmography div.content-container-search {
    padding: 0;
  }
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select {
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: #808080;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-styled {
  width: 100%;
  border: 1px solid hsla(0deg, 0%, 50%, 0.2);
  padding: 15px 30px;
  float: left;
  margin-bottom: 20px;
  font-family: Raleway, sans-serif;
  font-weight: 300;
  background-color: #FFF;
  position: relative;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-styled:after {
  content: "";
  position: absolute;
  right: 20px;
  display: inline-block;
  top: 45%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  transform: rotate(-45deg);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: #2ab6b7;
  border-left-color: #2ab6b7;
  transition: border-bottom-color 0.2s, border-left-color 0.2s 0.1s, transform 0.4s;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-options {
  display: none;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.9411764706);
  position: absolute;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-options:after {
  content: "";
  position: absolute;
  right: 20px;
  display: inline-block;
  top: 27px;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  transform: rotate(135deg);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: #2ab6b7;
  border-left-color: #2ab6b7;
  transition: border-bottom-color 0.2s, border-left-color 0.2s 0.1s, transform 0.4s;
  z-index: 100;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-options li {
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  transition: 0.3s;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-options li:hover {
  color: #2ab6b7;
  background: #FFF;
}
.filmography div.section div.section-inner-filmography div.content-container-search .medium .custom-select .select .select-options li[rel=hide] {
  display: none;
}
.filmography ul.breadcrumb {
  bottom: initial;
  top: 90vh;
  left: 60px;
}

a.button-close-news {
  position: absolute;
  right: 720px;
  top: 20px;
  fill: white;
  height: 20px;
  width: 20px;
}
@media screen and (max-width: 1400px) {
  a.button-close-news {
    right: calc(50% + 20px);
  }
}
@media screen and (max-width: 768px) {
  a.button-close-news {
    display: none;
  }
}
div.section-content a.button-close-news {
  position: relative;
  margin-bottom: 10px;
  right: unset;
  top: unset;
  display: none;
  fill: #fff;
  height: 20px;
  width: 20px;
}
@media screen and (max-width: 768px) {
  div.section-content a.button-close-news {
    display: block;
  }
}
@media screen and (max-width: 510px) {
  div.section-content h2.news {
    font-size: 40px !important;
    padding-right: 20px;
    width: fit-content;
  }
}

@media screen and (max-width: 1400px) {
  div.section-inner#news-section-inner {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  div.section-inner#news-section-inner {
    width: 100%;
  }
}
@media screen and (max-width: 510px) {
  div.section-inner#news-section-inner {
    width: 100%;
  }
}
div.section-inner#news-section-inner div.section-inner-block {
  min-height: 330px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 70px;
}
@media screen and (max-width: 510px) {
  div.section-inner#news-section-inner div.section-inner-block {
    height: fit-content;
    padding-bottom: 20px;
  }
}
div.section-inner#news-section-inner div.section-inner-block h4 {
  top: 0;
  height: fit-content;
  max-width: 80%;
  transform: none;
  margin-bottom: 0;
  flex-shrink: unset;
}
div.section-inner#news-section-inner div.section-inner-block p {
  color: white;
  font-weight: 400;
  position: relative;
  height: inherit;
}
div.section-inner#news-section-inner div.section-inner-block span.date {
  color: white;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}
div.section-inner#news-section-inner div.section-inner-block a {
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 20px;
  min-width: 100px;
}
@media screen and (max-width: 510px) {
  div.section-inner#news-section-inner div.section-inner-block a {
    width: auto;
    position: relative;
    bottom: 0;
  }
}
div.section-inner#news-section-inner div.section-inner-block a div.cta.cta-inner {
  top: 0;
  transform: none;
  position: relative;
  width: auto;
  right: inherit;
}
@media screen and (max-width: 510px) {
  div.section-inner#news-section-inner div.section-inner-block a div.cta.cta-inner {
    left: unset;
  }
}

@media screen and (max-width: 768px) {
  div.section.section-news {
    display: inline-table;
    height: 100vh;
  }
}
@media screen and (max-width: 768px) {
  div.section.section-news > div,
  div.section.section-news .fp-tableCell {
    display: block;
    height: inherit;
  }
}
@media screen and (max-width: 768px) {
  div.section.section-news > div div.section-content,
  div.section.section-news .fp-tableCell div.section-content {
    margin-bottom: 0;
  }
}