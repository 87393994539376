
.section-background{
	
	.section-background-img-filmo{
		filter: blur(8px);
	}
	img{
			height: 60%;
			position: absolute;
			top: 50%;
			left: calc((100% - 700px)/2);
			transform: translate(-50%, -50%);
			z-index: 100;

			@media screen and (max-width: 768px) {
				transform: translate(0);
				display: block;
				position: initial;
				margin: 0 auto;
				height: 200px;
			}
			@media screen and (max-width: 1024px) and (orientation: portrait) {
				height: 40%;
				left: calc((100% - 500px)/2);
			}
			@media screen and (max-width: 1024px) and (orientation: landscape) {
				left: calc((100% - 490px)/2);
			}
			@media screen and (max-width: 768px) and (orientation: landscape) {
				left: calc((100% - 500px)/2);
			}
			@media screen and (max-width: 768px) and (orientation: portrait) {
				height: 330px;
			}
		}
}
div.section div.section-inner{

	a.btn_back{
		color: #000;
		text-decoration: none;
		position: relative;
		transition:.2s;
		position: absolute;
		top: 60px;
		&:before{
			content: "";
			position: absolute;
			left: -15px;
			display: inline-block;
			top: 45%;
			width: 7px;
			height: 7px;
			transform: rotate(45deg) translateX(-50%);
			border-style: solid;
			border-width: 1px;
			border-color: transparent;
			border-bottom-color: black;
			border-left-color: black;
			transition: .2s ease-in;
			@media screen and (max-width: 414px) {
				left: -9px;
			}
		}

		&:hover{
			color: #2ab6b7;
			&:before{
				border-bottom-color: #2ab6b7;
				border-left-color: #2ab6b7;
				left: -20px;
				@media screen and (max-width: 414px) {
					left: -9px;
				}			
			}
		}
		@media screen and (max-width: 768px) and (orientation: portrait) {
			top: 30px;
		}
		@media screen and (max-width: 510px) {
			top: 30px;
		}
		@media screen and (max-width: 414px) {
			top: 20px;
		}


	}

	div p span{
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		top: 20%;
		padding-top: 3em;
	}
	@media screen and (max-width: 414px) {
		padding-top: 3em;
	}
	.container{
		h2{
			text-transform: uppercase;
		}
		h3{
			font-size: 25px;
		}

		.movie_category{
			display: inline-block;
			padding: 0;
			list-style: none;

			li span{
				font-weight: 300;
			}
		}
		
		.film-information{

			div{
				margin-bottom: 1.5em;
				position: relative;
				margin-left: 35px;
				
				&:before{
					height: 25px;
					width: 23px;
					content: "";
					top: 0px;
					left: -35px;
					position: absolute;
				}

				&:first-child{
					p{
						display: inline-block;
						margin: 0;
					}
					
					&:before{
						background: url('../img/year.svg') no-repeat;					
					}
				}
				&:nth-child(2){
					
					&:before{
						background: url('../img/format.svg') no-repeat;					
					}
				}
				&:nth-child(3){
					
					&:before{
						
						background: url('../img/genre.svg') no-repeat;
					}
				}
				&:nth-child(4){
					
					&:before{
						background: url('../img/director-chair.svg') no-repeat;
					}
				}
				&:last-child{
					&:before{
						background: url('../img/avatar.svg') no-repeat;
					}
				}
				span{
					font-weight: 600;
				}
			}
			ul{
				display: inline;
				padding: 0;
					li{
						display: inline-block;
						span{
							font-weight: 100;
						}
					}
				}
			}	
		}
	}