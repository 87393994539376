.home {
	
	h2:first-of-type {
		max-width: 1024px;
		
		@media screen and (max-width: 1400px) {
			max-width: 720px;
		}
		
		@media screen and (max-width: 1200px) {
			max-width: 600px;
		} 
		
		@media screen and (max-width: 800px) {
			max-width: 500px;
		} 
	}
}

// .home.menu-open .burger-content{
// 	@media screen and (max-width: 411px) {
// 			position: absolute;
// 			height: 100vh;
// 		} 
// }

h3.news-h3 {
	width: 25%;

	@media screen and (max-width: 1400px) {

	}

	@media screen and (max-width: 1024px) {

	}
	@media screen and (max-width: 510px) {
		width: unset;
	}
}

div.section-inner-block.section-inner-block-front {
	width: 30%;
    height: 330px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 20px;

	@media screen and (max-width: 1400px) {
	}
	@media screen and (max-width: 1024px) {
	}
	@media screen and (max-width: 510px) {
		margin-top: 0;
		width: auto;
	}

	h4 {
		font-size: 28px;
		margin-bottom: 0;
		margin-top: 0;
		font-size: 30px;
	}

	span.date {
		color: #fff;
		position: relative;
		margin-bottom: 10px;
		font-size: 24px;
	}

	p {
		margin-top: 10px;
		font-size: 18px !important;
    	line-height: inherit !important;
	}

	a {
		text-decoration: none;

		div.cta.cta-inner {
			right: 0;
			left: unset;
			position: relative;
			bottom: 10px;
			width: fit-content;
		}
	}
}

div.section-content.news-section-content {
	z-index: 1;
	width: 90% !important; 
	display: flex; 
	align-items: center;

	@media screen and (max-width: 1400px) {

	}

	@media screen and (max-width: 1024px) {

	}
	@media screen and (max-width: 510px) {
		align-items: start;
		flex-direction: column;
	}

	div {

		&:nth-of-type(even) {

			@media screen and (max-width: 1400px) {

			}
		
			@media screen and (max-width: 1024px) {
		
			}
			@media screen and (max-width: 510px) {
				display: none;
			}
		  }
	}
}