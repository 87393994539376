$background: #e74c3c;
$select-color: #fff;
$select-background: #c0392b;
$select-width: 220px;
$select-height: 40px;

.filmography {
	
	div.section {
		
		height: 100%;

		.section-content{
			top: 2%;


			h1{
				margin: 0;
				text-transform: uppercase;
				font-size: 95px;
				line-height: 90px;

				@media screen and (max-width: 1400px) {
					font-size: 65px;
				}

				@media screen and (max-width: 1200px) {
					font-size: 55px;
				}
				@media screen and (max-width: 450px) {
					font-size: 45px;
				}
				@media screen and (max-width: 320px) {
					font-size: 35px;
				}
			}
		}
		
		> ul:not(.breadcrumb) {
			
			margin: 0;
			padding: 0;
			list-style: none;
			position: absolute;
			top: 50vh; 
			right: 60px;
			padding-top: 3em;
			display: flex;
    		flex-wrap: wrap;

			@media screen and (max-width: 1400px) {
				
			}
			@media screen and (max-width: 768px) {
				top: 20vh; 
				padding-top: 0;
			}
			@media screen and (max-width: 414px) {
				top: 9vh; 
			}

			@media screen and (max-width: 660px) {
				margin: 0;
				padding: 0;
				list-style: none;
				position: absolute;
				padding-top: 5em;
				margin: 0 auto;
				display: block;
				width: 320px;
				left: 50%;
				transform: translate(-50%);
			}

			li {
				display: flex;
				color: white;

				@media screen and (max-width: 414px) {
					display: inline;
				}

				ul {
					display: inline-block;
					margin: 0;
					padding: 0;
					list-style: none;
					max-width: 230px;

					@media screen and (max-width: 1400px) {
						max-width: 200px;
					}

					@media screen and (max-width: 768px) {
					    max-width: 140px;
					}

					@media screen and (max-width: 660px) {
						max-width: 155px;
						min-width: 155px;
					}
					@media screen and (max-width: 414px) {
						max-width: 99px;
						min-width: 95px;
					}
					// @media screen and (max-width: 414px) {
					// 	max-width: 100px;
					// 	min-width: 100px;
					// }


					li {
						display: block;
						text-align: center;
						padding: 0px 20px;

						@media screen and (max-width: 1400px) {
							padding: 0px 5px;
						}

						img {

							max-height: 100px;
							@media screen and (max-width: 1400px) {
								// max-width: 80px;
							}
							@media screen and (max-width: 414px) {
								max-height: 89px;

							}
						}

						span {
							font-size: 70px;
							font-weight: 800;

							@media screen and (max-width: 1400px) {
								font-size: 40px;
							}
						}

						p {
							font-size: 20px;

							@media screen and (max-width: 1400px) {
								font-size: 16px;
							}
						}
					}
					
				}

				span {

				}
			}
		}
		
		div.section-background {
			position: fixed;
			background-color: #1c1e1e;
			
			div.section-background-img {
				background-attachment: initial;
				background-position: 50% 0%;
				background-size: 100%;
				@media screen and (max-width: 1024px) {
					background-size: cover;
				}
			}
		}
		
		div.section-inner-filmography {
			position: relative;
			margin: 0 auto;
			max-width: 1250px;
			color: white;
			padding-top: 86vh;
	
			@media screen and (max-width: 1400px) {
				width: 100%;
				padding-left: 60px;
				padding-right: 60px; 
			}
			@media screen and (max-width: 768px) {
				padding-top: 20vh;
			}
			@media screen and (max-width: 414px) {
				padding-top: 50vh; 
				padding-left: 20px;
				padding-right: 20px; 
			}
			@media screen and (max-width: 320px) {
				padding-top: 63vh; 
			}

			div.content-container-search{

				@media screen and (max-width: 768px) {
					padding: 0;
				}
				.medium .custom-select .select {
					cursor: pointer;
					position: relative;
					font-size: 16px;
					color: #808080;
					.select-hidden {
					  display: none;
					  visibility: hidden;
					  padding-right: 10px;
					}
					.select-styled{
						width: 100%;
						border: 1px solid hsla(0,0%,50%,.2);
						padding: 15px 30px;
						float: left;
						margin-bottom: 20px;
						font-family: Raleway,sans-serif;
						font-weight: 300;
						background-color: #FFF;
						position: relative;

						&:after{
							content: "";
							position: absolute;
							right: 20px;
							display: inline-block;
							top: 45%;
							transform: translateY(-50%);
							width: 7px;
							height: 7px;
							transform: rotate(-45deg);
							border-style: solid;
							border-width: 1px;
							border-color: transparent;
							border-bottom-color: #2ab6b7;
							border-left-color: #2ab6b7;
							transition: border-bottom-color .2s,border-left-color .2s .1s,transform .4s;
						}

						&.active{

							// &:after{
							// 	transform: rotate(135deg);
							// 	z-index: 30;
							// }
						}
					}
					.select-options {
						display: none; 
						top: 100%;
						right: 0;
						left: 0;
						z-index: 999;
						margin: 0;
						padding: 0;
						list-style: none;
						background-color: #fffffff0;
						position: absolute;

						&:after{
							content: "";
							position: absolute;
							right: 20px;
							display: inline-block;
							top: 27px;
							transform: translateY(-50%);
							width: 7px;
							height: 7px;
							transform: rotate(135deg);
							border-style: solid;
							border-width: 1px;
							border-color: transparent;
							border-bottom-color: #2ab6b7;
							border-left-color: #2ab6b7;
							transition: border-bottom-color .2s,border-left-color .2s .1s,transform .4s;
							z-index: 100;
						}
						li {
							margin: 0;
							padding: 12px 0;
							text-indent: 15px;
							transition: .3s;
							&:hover {
							  color: #2ab6b7;
							  background: #FFF;
							}
							&[rel="hide"] {
							  display: none;
							}
					  	}	
					}
				}
			}
		}
	}
	
	ul.breadcrumb {
		bottom: initial;
		top: 90vh;
		left: 60px;
	}
	
}