header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 60px;
	z-index: 10;
	padding-bottom: 0;
	pointer-events: none;
	height: 100px;
	z-index: 100;	

	& > * {
		pointer-events: auto;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		display: inline-block;
		background-image: linear-gradient(180deg,rgba(0, 0, 0, 0.53),transparent 95%);
	}

	h1 {
		text-indent: -9999px;
	}
	
	.logo {
		img {
				position: absolute;
		    top: 38px;
		    left: 40px;
		    width: 160px;

			@media screen and (max-width: 1400	px) {
				left: 40px;
				top: 40px;
			}
			@media screen and (max-width: 510px) {
				width: 120px;
				top: 30px;
				left: 20px;
			}
		}	
	}
	
	.languages {
		
		position: fixed;
		top: 5.3%;
		right: 130px;
		margin: 0;
		padding: 0;
		transition: .3s ease;
		@media screen and (max-width: 414px) {
			top: 30px;
		    right: 85px;
		    text-align: right;
		}

		@at-root {
			.inner & {
				top: 4.9%;
				@media screen and (max-width: 1024px) and (orientation: landscape) {
					top: 3.8%;
				}
				@media screen and (max-width: 768px) and (orientation: portrait) {
					top: 30px;
				}
				@media screen and (max-width: 560px) {
					position: absolute;
				}
				// @media screen and (max-width: 480px) {
				// 	top: 2.3%;
				// }
				@media screen and (max-width: 414px) {
					top: 30px;
				    right: 85px;
				    text-align: right;
				}
			}
		}
				
				@media screen and (min-width: 1400px) {
					top: 40px;	
					right: 140px;
				}	
		
		li {
			
			display: inline-block;
			color: #CCC;
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: 600;
			transition: 0.4s;
			
			&.active, &:hover {
				
				color: white;
				text-decoration: underline;
				
				@at-root {
					.inner & {
						color: black;
					}
				}
				
				@media screen and (max-width: 480px) {
					color: #CCC !important;
					text-decoration: underline;	
				}
			}
			
			a {
				color: inherit;
				text-decoration: none;
				display: block;
				width: 100%;
				height: 100%;
				padding: 1em 0.5em;
				color: #FFF;

				@media screen and (max-width: 430px) {
					font-size: 12px;
				}
			}
		}
	}
}

div.burger-background {
	position: fixed;
	background-color: rgba(black, .5);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: none;
}

div.burger-content {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 450px;
	background: white;
	padding: 60px 0px;
	transform: translateX(500px);
	transition: .5s ease;
	padding-top: 150px; 
	z-index: 10;
	overflow-y: scroll;

	li.active > a{
		color: #2ab6b7;
	    opacity: 1;
	}

	@media only screen and (max-width: 1400px) {
		 padding-top: 130px;
	}
	@media only screen and (max-width: 414px) {
		 padding-top: 90px;
	}

	body.menu-open & {
		transform: translateX(0px);
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: right;

		&:hover a {
			opacity: .5;
		}

		li {

			a {
				color: inherit;
				text-decoration: inherit;
				font-size: 20px;
				font-weight: 800;
				font-style: italic;
				//line-height: 45px;
				transition : .3s ease;
				padding: 8px 0px;
				display: block;
				padding-right: 60px;
				background: rgba(black,0);

				@media only screen and (max-width: 1400px) {
					font-size: 18px;
					padding: 1.2vh 0px;
					padding-right: 60px;
				}
				@media only screen and (max-width: 510px) {
					padding-right: 30px;
				}

				&:hover {
					padding-right: 70px;
					opacity: 1;
					background: rgba(black,.05);
				}
			}

			ul li a {
				font-weight: 400;
				font-style: initial;
			}
		}
	}
}

.burger-menu {
	position: absolute;
	top: 43px;
	right: 60px;
	width: 60px;
	height: 50px;
	cursor: pointer;
	z-index: 11;
	transition: .3s ease;

	@media screen and (max-width: 1400px) {
		right: 40px;
		top: 25%;
	}
	@media screen and (max-width: 510px) {
		right: 20px;
		top: 45%;
	}
	@media screen and (max-width: 414px) {
		top: 3%;
		position: fixed;
	}

	body.mini-burger & {
		top: 20px;
		right: 20px;
		width: 40px;
		height: 35px;
	}
}

body.mini-burger header ul.languages{
			top: 15px;
    		right: 90px;
		}
.burger {
	position: absolute;
	background: white;
	width: 80%;
	height: 4px;
	top: 50%;
	right: 0px;
	margin-top: -5px;
	opacity: 1;

	// .burger-menu.menu-on &, body.inner:not(.filmography) & {
	// 	background: black;
	// 	@media screen and (max-width: 768px) {
	// 		background: white;
	// 	}
	// }

	body.inner:not(.filmography) .burger-menu.menu-on & {
		background: white;
	}
}
.burger::before {
	position: absolute;
	background: white;
	width: 100%;
	height: 4px;
	top: 15px;
	content: "";
	display: block;

	body.mini-burger & {
		top: 12px;
	}



	// .burger-menu.menu-on &, body.inner:not(.filmography) & {
	// 	background: black;

	// 	@media screen and (max-width: 768px) {
	// 		background: white;
	// 	}
		
	// }

}
.burger::after {
	position: absolute;
	background: white;
	width: 100%;
	height: 4px;
	bottom: 15px;
	content: "";
	display: block;

	body.mini-burger & {
		bottom: 12px;
	}

	// .burger-menu.menu-on &, body.inner:not(.filmography) &{
	// 	background: black;

	// 	@media screen and (max-width: 768px) {
	// 							background: white;
	// 					}
	// }
}
.burger::after, .burger::before, .burger {
	transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;
}
.burger-menu.menu-on .burger::after{
	 transform: rotate(-45deg);
	 -webkit-transform: rotate(-45deg);
	bottom: 0px;
	background-color: #000;
}
.burger-menu.menu-on .burger::before{
	 transform: rotate(45deg);
	 -webkit-transform: rotate(45deg);
	top: 0px;
	background-color: #000;
}
.burger-menu.menu-on .burger{
	background: rgba(111,111,111,.0);
}

body.inner:not(.filmography){
	header .burger-menu .burger{
		background-color: #000;
		@media screen and (max-width: 768px) and (orientation: portrait) {
			background-color: #FFF;
		}
		@media screen and (max-width: 414px) {
			background-color: #FFF;
		}
		&:before,
		&:after{
			background-color: inherit;
		}
	}
}
body.inner:not(.filmography) header .burger-menu.menu-on{
	.burger{
		background-color: #FFF;
		&:before,
		&:after{
			background-color:#000;
		}
		@media screen and (max-width: 768px) {
			background-color: #FFF;

			&:before,
			&:after{
				background-color:#000;
			}
		}

	}
}