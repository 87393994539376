
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

::-webkit-scrollbar { 
	display: none; 
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Raleway', sans-serif;
	//overflow: hidden;
	//height: auto;	   
}

#tv {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	opacity: .2;
}
// ul{
// 	list-style: none;
// }

div.showreel {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(black, .65);
	z-index: 12;
	display: none;

	iframe {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		margin: 0 auto;
		height: 60%;
		width: 40%;

		@media screen and (max-width: 411px) {
			height: 30%;
			width: 90%;
		}
	}
}

ul.breadcrumb {
	position: absolute;
	padding: 0;
	margin: 0;
	list-style: none;
	bottom: 83px;
	left: 150px;

	body.inner & {
		@media screen and (max-width: 768px) {
				position: relative;
			}
	}

	li {
		display: inline-block;
		color: white;
		font-style: italic;

		&:last-of-type {
			a {
				font-weight: 600;
			}
		}

		a {
			text-decoration: none;
			color: white;
			font-style: italic;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

ul.grid {
	width: 100%;
	max-width: 1350px;
	margin: 0 auto;
	height: 100%;
	position: fixed;
	padding: 0;
	margin: 0;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	list-style: none;
	pointer-events: none;

		@media only screen and (max-width: 1600px) {
			max-width: calc(100% - 400px);
		}

		@media only screen and (max-width: 1024px) {
			max-width: calc(100% - 100px);
		}

		@media only screen and (max-width: 700px) {
			max-width: calc(100% - 30px);
		}

	li {
		display: inline-block;
		height: 100%;
		width: calc(100% / 6);
		float: left;
		border-left: 1px solid rgba(white, .2);

		&:last-of-type {
			border-right: 1px solid rgba(white, .2);
		} 

		body.inner & {
			&:nth-child(5), &:nth-child(6) {
				display: none;
			}
		}
	}

	&:before {
		content: '';
		width: 105px;
		height: 100%;
		position: absolute;
		border-left: 1px solid rgba(white, .2);  
		top: 0;
		left: -105px;
	} 

	&:after {
		content: '';
		width: 105px;
		height: 100%;
		position: absolute;
		border-right: 1px solid rgba(white, .2);  
		top: 0;
		right: -105px;
	} 
} 


// .wrap {
//   height: 100%;  
//   position: absolute;
//   overflow: hidden;
//   z-index: 99;
//   width: 100%;
//   top: 0;

//   img, .bg {
	
	
//   }
  
//   .bg {
//     width: 100%;
//     height: 100%;
	
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1;
	
//     background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/167792/mountains_copy.jpg') no-repeat center center;
//     background-size: cover;

//     transform: scale(1.1);
//   }
// }

.fp-auto-height {
	padding-top: 0 !important;
}

div.fullpage-inner {

}

div.section {
	background: black;
	position: relative;
	height: 100%;
	width: 100%;

	&.scrollable{
		@media screen and (max-width: 411px) {
			height: 100vh;		
		}
	}

	div.section-background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;

		
		div.section-background-img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			//z-index: -1;
			transform: scale(1.1);
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-position: center;

			@media screen and (max-width: 765px) {
				background-position: center;
			}
			@media screen and (max-width: 415px) {
				background-attachment: scroll;
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 50%;
			background: linear-gradient(to right, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);
			
			body.film & {
				content: none;
			}
		}

		body:not(.inner) &:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 50%;
			background: linear-gradient(to left, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 100%);
			z-index: 1;
			pointer-events: none;

			body.film & {
				content: none;
			}
		}
	}

	div.section-inner {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 700px;
		background: white;
		padding: 80px;
		padding-top: 100px;
		overflow-y: auto;
		
		&.section-inner_team{
			width: 60%;
		}

		ul li {
			line-height: 2;
		}

		.container a {
			color:#2ab6b7;
			text-decoration: none;
			&.link_download{
				color: #000000;
				position: relative;
				transition: .2s ease;
				&:after{

					height: 25px;
					width: 23px;
					content: "";
					top: 0px;
					right: -45px;
					position: absolute;
					background: url('../img/download_black.svg') no-repeat;	
				}

				&:hover{
					color:#2ab6b7;

					&:after{
						background: url('../img/download_color.svg') no-repeat;	
					}
				}
			}
		}

		@media screen and (max-width: 1400px) {
			width: 50%;
		}

		 @media screen and (max-width: 768px) {
			width: 100%;
			position: relative;
			//margin-top: 100%;
			//top: 100vh;
			overflow: hidden;
			top: initial;
			right: initial;
			height: auto;
		 }
		@media screen and (max-width: 510px) {
			padding: 1.5em;
		}



		// div.container {
		//     display: block;
		// }

		h3, h4 {
			text-transform: uppercase;
			font-size: 35px;
		}

		h4 {
			font-size: 25px;
		}

		.member {
			p{
				font-weight: 300;
				display: none;
			}
			a{
			    font-size: 14px;
			    font-style: italic;
			    font-weight: 100;
			}
			h4{
				line-height: 25px;
				font-size: 1em;
				font-weight: 600;
				text-transform: none;
				position: relative;
				transition:.3s;
				padding-right: 20px;
				margin-bottom: 5px;

				// &:hover{
				// 	color: #2ab6b7;
					
				// }


				// &:after {
				// 	content: "";
				// 	position: absolute;
				// 	right: 3px;
				// 	display: inline-block;
				// 	top: 4px;
				// 	width: 7px;
				// 	height: 7px;
				// 	transform: rotate(-45deg);
				// 	border-style: solid;
				// 	border-width: 1px;
				// 	border-color: transparent;
				// 	border-bottom-color: black;
				// 	border-left-color: black;
				// 	transition:
				// 		border-bottom-color .2s,
				// 		border-left-color .2s .1s,
				// 		transform .4s;
				// }

				// /* At this point, the & at this level of tabulation means "header nav" */
				// &:hover:after{
				// 	border-bottom-color: #2ab6b7;
				// 	border-left-color: #2ab6b7;
				// }
			}
			h4.transform-after {
				&:after{
					transform: translateY(4px) rotate(135deg);

				}
			}

		}

		.member:nth-child(1){
			h4{
				cursor: pointer;
				&:hover{
					color: #2ab6b7;	
				}


				&:after {
					content: "";
					position: absolute;
					right: 3px;
					display: inline-block;
					top: 4px;
					width: 7px;
					height: 7px;
					transform: rotate(-45deg);
					border-style: solid;
					border-width: 1px;
					border-color: transparent;
					border-bottom-color: black;
					border-left-color: black;
					transition:
						border-bottom-color .2s,
						border-left-color .2s .1s,
						transform .4s;
				}
				&:hover:after{
					border-bottom-color: #2ab6b7;
					border-left-color: #2ab6b7;
				}
			}
		}

		p {
			line-height: 25px;
			font-weight: 300;

			a{
				text-decoration: none;
				color: black;

		// 		&:after{
		// 			content: "";
					// position: absolute;
					// right: 3px;
					// display: inline-block;
					// top: 4px;
					// width: 7px;
					// height: 7px;
					// transform: rotate(-45deg);
					// border-style: solid;
					// border-width: 1px;
					// border-color: transparent;
					// border-bottom-color: black;
					// border-left-color: black;
					// transition:
					// border-bottom-color .2s,
					// border-left-color .2s .1s,
					// transform .4s;
		// 		}
			}

			img {
				height: auto !important;
				width: auto !important;
				max-width: 100%;
			}
		}

		a.next-content {
			position: absolute;
			text-decoration: none;
			color: grey;
			font-style: italic;
			bottom: 80px;
		}

		div.section-inner-block {
			width: 100%;
			height: 140px;
			background: purple;
			margin-top: 30px;
			padding: 20px;
			position: relative;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			position: relative;


			@media screen and (max-width: 1265px) {
				height: 180px;
				padding: 20px;
			}

			@media screen and (max-width: 510px) {
				height: 160px;
				padding: 20px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 50%;
				background: linear-gradient(to right, rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);
			}

			h4 {
				margin-top: 0;
				text-transform: uppercase;
				color: white;
				font-size: 23px;
				position: relative;
				max-width: 53%;
				top: 50%;
				transform: translateY(-50%);

				@media screen and (max-width: 1265px) {
					top: 20%;
				}

				@media screen and (max-width: 510px) {
					top: 10%;
					max-width: 100%;
					padding-top: 20px;
				}

			}

			div.cta-inner {
				right: 20px;
				bottom: 40px;
				left: initial;
				top: 50%;
				transform: translateY(-50%);
				background-color: #7979793d;

				@media screen and (max-width: 1265px) {
					right: inherit;
					left: auto;
					top: 70%;
				}

				@media screen and (max-width: 510px) {
					left: 20px;
					top: 70%;
				}


			}
		}
	}

	ul.logo_scope {
		display: flex;
		align-items: center;
		padding: 0;
		justify-content: space-between;
	    width: auto;
	    position: absolute;
	    bottom: 0%;
	    left: 60px;
	    right: calc(700px + 60px);
	    -webkit-transform: translate(-50%,-50%);
	    -ms-transform: translate(-50%,-50%);
	    transform: translate(0%,-50%);
	    z-index: 4;
	    transition: .2s ease;

	    @media screen and (max-width: 1150px) {
			flex-wrap:wrap;
		}

	    @media screen and (max-width: 1350px) {
			right: 670px;
		}
		@media screen and (max-width: 1024px) and (orientation: landscape) {
	 		right: 570px;
	 		left: 40px;
	 	}

	 	@media screen and (max-width: 1024px) {
	 		right: 570px;
	 	}
		@media screen and (max-width: 768px) {
		 	right: 0;
		    top: 40%;
		    flex-wrap: inherit;
		    width: auto;
		    left: 40px;
		}
		@media screen and (max-width: 768px) and (orientation: portrait) {
	 		top: 50%;
	 	}
		@media screen and (max-width: 490px) {
			flex-wrap:wrap;
			height: 120px;
			left: 20px;
			top: 47%;
		}
		@media screen and (max-width: 320px) {
			top: 37%;
		}




		    li{
		    	opacity: .5;
		    	transition: .2s ease;
		    	pointer-events:none;
		    }

		    li.active-logo{
		    	opacity: 1;
		    	transform: scale(1.2);
		    	transition: .2s ease;

		    }

		    &:hover{
		    	transition: .2s ease;

		    	li:hover{
		    		opacity: 1;
		    	}
		    }


			li{
				display: inline-block;
				width: calc(25% - 20px);

				@media screen and (max-width: 1150px) {
					width: calc(50% - 20px);
				}
				@media screen and (max-width: 1768px) {
					width: calc(25% - 20px);
				}
				@media screen and (max-width: 490px) {
					width: calc(50% - 20px);
				}
			}

			img {
				width: 80%;
				@media screen and (max-width: 1350px) {
					width: 110%;
				}
				@media screen and (max-width: 768px) {
					width: 80%;
				}
				@media screen and (max-width: 490px) {
					width: 70%;
				}
			}
		}

	div.section-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: white;
		left: 60px;
		width: 46%;

		@media screen and (max-width: 1400px) {
			left: 40px;
		}
		@media screen and (max-width: 768px) {
			width: 76%;
		}
		@media screen and (max-width: 510px) {
			width: 80%;
			left: 20px;
		}
		@media screen and (max-width: 411px) {
			top: 50%;
		}

		body.film & {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0;
			width: calc(100% - 700px);
		}

		body.inner & {
			@media screen and (max-width: 768px) {
				top: initial;
				transform: none;
				margin-top: 200px;
				position: relative;
				margin-bottom: 150px;
			}
		}

		div.section-content-film {
			
			img {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: 0 auto;
				max-height: 70%;
				//width: 80%;
				display: block;
				box-shadow: 10px 10px 150px 0px rgba(0,0,0,0.50);



			}

			&:after {
					content:'';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					right: 0;
					height: 120px;
					width: 120px;
					background-image: url("../img/director-chair.svg");
					background-size: cover;
					background-position: 50% 50%;
					margin: 0 auto;
					display: block;
				}
		}

		h2 {
			margin: 0;
			text-transform: uppercase;
			font-size: 85px;
			line-height: 90px;
			
			@at-root {
				body.home & {
					width: 100%;
				}
			}

			@media screen and (max-width: 1400px) {
				font-size: 75px;
				line-height: 70px;
			}
			@media screen and (max-width: 1285px) {
				font-size: 61px;
				line-height: 63px;
			}
			@media screen and (max-width: 1272px) {
				font-size: 62px;
				line-height: 60px;
			}
			@media screen and (max-width: 1200px) {
				font-size: 45px;
				line-height: 60px;
			}
			@media screen and (max-width: 320px) {
				font-size: 31px;
				line-height: 32px;
			}

			// @media screen and (max-width: 1200px) {
			//     font-size: 50px;
			//     line-height: 55px;
			// }
		}

		h3 {
			margin: 0;
			text-transform: uppercase;
			font-size: 75px;
			line-height: 80px;



			@media screen and (max-width: 1400px) {
				font-size: 60px;
				line-height: 65px;
			}

			@media screen and (max-width: 1024px) {
				font-size: 50px;
				line-height: 55px;
			}
			@media screen and (max-width: 510px) {
				font-size: 40px;
				line-height: 44px;
			}
			@media screen and (max-width: 320px) {
				font-size: 31px;
				line-height: 32px;
			}
		}

		p {
			font-weight: 300;
			font-style: italic;
			font-size: 22px;
			max-width: 610px;
			line-height: 40px;

			@media screen and (max-width: 1400px) {
				max-width: 45vw;
				font-size: 16px;
				line-height: 24px;
			}
			@media screen and (max-width: 510px) {
				max-width: 65vw;
			}

			 body.inner & {
				@media screen and (max-width: 768px) {
					max-width: 80%;
				}
				@media screen and (max-width: 510px) {
					max-width: 100%;
				}
			}
		}
	}

	div.cta {
		position: absolute;
		bottom: 40px;
		height: 50px;
		width: auto;
		border: white 4px solid;
		cursor: pointer;
		overflow: hidden;
		transition: .3s ease;
		left: 120px;

		@media screen and (max-width: 1400px) {
			left: 120px;
			// bottom: 15px;
		}
		@media screen and (max-width: 510px) {
			width: 220px;
		}
		@media screen and (max-width: 320px) {
			left: 80px;
		}


		span {
			color: white;
			text-transform: uppercase;
			font-weight: 600;
			padding: 0px 29px;
			font-size: 16px;
			position: relative;
			top: 13px;
			z-index: 1;
			transition: .3s ease;

			@media screen and (max-width: 510px) {
				transform: translateX(-50%);
				width: 100%;
				text-align: center;
				position: absolute;
				left: 50%;
				padding: 0 10px;
			}

			@media screen and (max-width: 411px) {
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				width: 100%;
				text-align: center;
				position: absolute;
				left: 0;
				padding: 0 10px;
				top: 50%;
				transform: translateY(-50%);
			}

		}

		&:before {
			content: '';
			background-color: white;
			transform: translateX(-100%);
			position: absolute;
			height: 100%;
			width: 100%;
			transition: .3s ease;
		  }

		  &:hover {
			
			span {
				color: black;
			}

			&:before {
			  transform: translateX(0);
			}
		  }
	}

	div.next {
		position: absolute;
		bottom: 40px;
		left: 60px;
		height: 50px;
		width: 49px;
		border: white 4px solid;
		overflow: hidden;
		transition: .3s ease;

		@media screen and (max-width: 1400px) {
			left: 40px;
			// bottom: 15px;
		}
		@media screen and (max-width: 320px) {
			left: 20px;
		}

		body.inner & {
			&:after {
				transform: translateY(-50%) rotate(45deg);
			}

			@media screen and (max-width: 768px) {
				position: relative;
			}
			@media screen and (max-width: 411px) {
				position: absolute;
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-70%) rotate(-45deg);
			height: 15px;
			width: 15px;
			left: 0;
			right: 0;
			margin: 0 auto;
			border-left: white 4px solid;
			border-bottom: white 4px solid;
			transition: .3s ease;

		}

		&:before {
			content: '';
			background-color: white;
			transform: translateX(-100%);
			position: absolute;
			height: 100%;
			width: 100%;
			transition: .3s ease;
		  }

		  &:hover {
			
			span {
				color: black;
			}

			&:before {
			  transform: translateX(0);
			}

			&:after {
				border-left: black 6px solid;
				border-bottom: black 6px solid;
			}
		  }
	}
}

	div.infos {
		position: fixed;
		text-align: right;
		color: white;
		right: 40px;
		bottom: 40px;
		z-index: 9;

		// @media screen and (max-width: 1400px) {
		// 	right: 40px;
		// 	bottom: 10px;
		// }

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			margin-bottom: 10px;

			li {
				display: inline-block;

				a {
					color: white;
					text-decoration: none;
					margin-left: 20px;
					font-size: 22px;
				}
			}
		}

		span {
			display: block;
			font-weight: 300;
			line-height: 20px;
			font-size: 15px;
		}
	}

div.nav {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	right: 60px;
	z-index: 9;
	text-align: right;

	@media screen and (max-width: 1400px) {
		right: 40px;
	}
	@media screen and (max-width: 510px) {
		display: none;
	}

	&.inner {
		right: 700px;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: right;
		display: block;

		li {
			display: block;
			text-align: right;

			&.active a {
				opacity: 1;
				padding-right: 85px;
				color: rgba(white, 1);
				
				&:after {
					right: 30px;
				}   
			}

			a {
				position: relative;
				display: block;
				text-align: right;
				padding: 20px 0px;
				text-decoration: none;
				color: white;
				font-style: italic;
				font-weight: 300;
				line-height: 3px;
				padding-right: 55px;
				transition: .3s ease;
				opacity: .5;
				color: rgba(white, 0);

				&:hover, &.active {
					opacity: 1;
					padding-right: 85px;
					color: rgba(white, 1);

					&:after {
						right: 30px;
					}
				}


				&:after {
					content: '';
					position: absolute;
					right: 0;
					height: 4px;
					width: 40px;
					background: white;
					transition: .3s ease;
				}
			}
		}
	}
}


#map-canvas{
	height: 300px;
}

div.section div.section-inner {
	.contact-info .contact-info_adress{
		h4{
			margin-top: 70px;
		}
		
		p{
			display: inline-block;
			margin-right: 10px;

			&:last-child{
				margin-bottom: 50px;
			}

			a{
				color: #2ab6b7;
			}
		}
	}

	div.section-inner-block.job-block{
		
		
		background-image: url('../img/job_recruteCeo.jpg');
		&:nth-child(1){
			
			
			background-image: url('../img/candidature.jpg');
		}
		&:nth-child(2){
			
			
			background-image: url('../img/job_production.jpg');
		}
		&.job-adjoint{
			
			
			background-image: url('../img/220127_jobproduction.jpg');
		}
		&:nth-child(3){
			background-image: url('../img/img_stage.jpg');
		}

		h4{
			@media screen and (max-width: 1264px) {
				max-width: 83%;
			}

			@media screen and (max-width: 560px) {
				max-width: 93%;
				font-size: 20px;
			}
		}

	}
}














